import { notification } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CarItem } from "../../components/car/CarTypes";
import { PhoneConfirm } from "../../components/common/phoneConfirm/phoneConfirm";
import { UserCarsList } from "../../components/user/cars/components/carList";
import { CarInfo } from "../../components/user/cars/components/info";
import { RemoveConfirmPopUp } from "../../components/user/cars/components/removeConfirmPopUp";
import axios from "../../helpers/interseptor";

const WrapperStyled = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 32px;
  align-items: flex-start;
`;

const ContainerStyled = styled.div`
  margin-top: 15px;
`;

export const UserCars = () => {
  const { t } = useTranslation();
  const [cars, setCars] = useState<CarItem[]>([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [carId, setCarId] = useState<string | undefined>();

  useEffect(() => {
    const controller = new AbortController();
    axios
      .get<CarItem[]>("/car/user-cars", {
        signal: controller.signal,
      })
      .then((res) => res.data)
      .then((data) => setCars(data))
      .catch((e) => console.log(e));
    return () => controller.abort();
  }, []);

  const handleDelete = (id: string) => {
    setIsConfirmOpen(true);
    setCarId(id);
  };

  const confirmDelete = (id: string, data: { selled: boolean; rating: number }) => {
    axios
      .delete(`car/delete/${id}/${data.selled ? "true" : "false"}/${data.rating}`)
      .then((res) => {
        if (res.status === 200) {
          setCars(cars.filter((car) => car._id !== id));
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setIsConfirmOpen(false);
        setCarId("");
      });
  };

  const handleCloseConfirm = () => {
    setIsConfirmOpen(false);
    setCarId("");
  };

  const handleUpToTop = (id: string) => {
    axios
      .post<CarItem>(`car/up-to-top/${id}`)
      .then((res) => {
        setCars(
          cars.map((car) => {
            if (car._id === id) {
              return res.data;
            }
            return car;
          }),
        );
        notification.success({
          message: t("user.car.up-success"),
        });
      })
      .catch((e) => {
        notification.error({
          message: t("user.car.up-error", {
            lastUp: new Date(e.response?.data.time)?.toLocaleString("uk-UA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              hour12: false,
              minute: "2-digit",
              second: "2-digit",
            }),
          }),
        });
      });
  };

  const handleActive = useCallback(
    (id: string) => {
      axios
        .post<CarItem>(`car/active/${id}`)
        .then((res) => {
          notification.success({
            message: t(res.data.isActive ? "user.car.active" : "user.car.inactive"),
          });
          setCars(
            cars.map((car) => {
              if (car._id === id) {
                return res.data;
              }
              return car;
            }),
          );
        })
        .catch((e) => console.log(e));
    },
    [cars, t],
  );
  const viewCount = useMemo(() => cars.reduce((acc, car) => acc + car.views, 0), [cars]);
  const likesCount = useMemo(() => cars.reduce((acc, car) => acc + car.likes, 0), [cars]);
  return (
    <ContainerStyled>
      <PhoneConfirm />
      <WrapperStyled>
        <CarInfo carCount={cars.length} viewCount={viewCount} likesCount={likesCount} />
        <UserCarsList
          cars={cars}
          onActive={handleActive}
          onUpToTop={handleUpToTop}
          onDelete={handleDelete}
        />
        <RemoveConfirmPopUp
          isOpen={isConfirmOpen}
          carId={carId}
          onClose={handleCloseConfirm}
          onConfirm={confirmDelete}
        />
      </WrapperStyled>
    </ContainerStyled>
  );
};

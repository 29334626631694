import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { FromToEnum } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { useSearch } from "../../../utils/useSearch";
import { FromToWrapperStyled, InputNumberStyled } from "../../searchFilter";

export const MileageInline = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isSet = useRef(false);
  const { manageSearch, removeSearch } = useSearch();
  const selectedMileage = useAppSelector((state) => state.filters.selectedData.mileage);
  const [mileage, setMileage] = useState(selectedMileage);
  const handleChangeMileage = (type: FromToEnum) => (value: string | null) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value as string) || value === "" || value === null) {
      setMileage((prev) => ({ ...prev, [type]: Number(value) || undefined }));
    }
  };

  useEffect(() => {
    if (selectedMileage && !isSet.current) {
      setMileage(selectedMileage);
      isSet.current = true;
    }
  }, [selectedMileage]);

  const handleBlur = (type: FromToEnum) => () => {
    if (mileage && mileage[type]) {
      dispatch(FiltersActions.setMileage({ type, value: mileage[type] as number }));
      let to, from;
      if (mileage.from && mileage.to && mileage.from > mileage.to) {
        to = mileage.from;
        from = mileage.to;
        setMileage({ from, to });
        manageSearch("mileageFrom", String(from));
        manageSearch("mileageTo", String(to));
        return;
      }
      manageSearch(type === FromToEnum.FROM ? "mileageFrom" : "mileageTo", String(mileage[type]));
    } else {
      removeSearch(type === FromToEnum.FROM ? "mileageFrom" : "mileageTo");
    }
  };

  return (
    <FromToWrapperStyled>
      <InputNumberStyled
        value={mileage?.from}
        onChange={handleChangeMileage(FromToEnum.FROM)}
        onBlur={handleBlur(FromToEnum.FROM)}
        min={0}
        step={1000}
        placeholder={t("from")}
      />
      <InputNumberStyled
        value={mileage?.to}
        onChange={handleChangeMileage(FromToEnum.TO)}
        onBlur={handleBlur(FromToEnum.TO)}
        min={0}
        step={1000}
        placeholder={t("to")}
      />
    </FromToWrapperStyled>
  );
};

import { Form, Input } from "antd";
import axios from "axios";

import { baseURLApi } from "../../../helpers/interseptor";
import { BTNTypes, ButtonStyled } from "../../common/Button";

export const LoginAdmin: React.FC<{ setAdminTocken: (value: string) => void }> = ({
  setAdminTocken,
}) => {
  const handleSubmit = (e: { login: string; password: string }) => {
    axios
      .post(`${baseURLApi}/admin/login`, e)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        setAdminTocken(data?.access_token);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{ remember: true }}
      onFinish={handleSubmit}
      //   onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="login"
        name="login"
        rules={[{ required: true, message: "Please input your login" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <ButtonStyled $type={BTNTypes.PRIMARY} type="primary" htmlType="submit">
          Submit
        </ButtonStyled>
      </Form.Item>
    </Form>
  );
};

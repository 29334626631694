import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";

import { AdditionalInfoPyaload, CarItem } from "../../../components/car/CarTypes";
// import { CarSlider } from "../../../components/car/components/carSlider";
import { AdditionalInfo } from "../../../components/car/components/preview/AdditionalInfo";
import { CaRBreadcrumbs } from "../../../components/car/components/preview/Breadcrumbs";
import { Contacts } from "../../../components/car/components/preview/Contacts";
import { GeneralInfo } from "../../../components/car/components/preview/GeneralInfo";
import { InfoItems } from "../../../components/car/components/preview/InfoItems";
import { Recommendation } from "../../../components/car/components/preview/Recommendation";
import { PreviewSlider } from "../../../components/car/components/preview/Slider";
import { AdditionalIonfoKeys } from "../../../components/car/constant";
import { NotFound } from "../../404/404";

export interface PreviewCarItem extends Omit<CarItem, "additionalOptions"> {
  additionalOptions: { [key in AdditionalIonfoKeys]: AdditionalInfoPyaload[] };
  user: { name: string; phone: string; userPhoto?: string; description?: string };
}

const CarPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 28px;
  gap: 32px;
`;

const CarPageContainerStyled = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;
const TitleStyled = styled.h1``;

const TitleWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 35px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0;
  & span {
    background: var(--main-color);
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
  }
`;

const CarWrapperStyled = styled.div`
  flex-grow: 1;
`;

const ContantWrapperStyled = styled.div`
  flex-grow: 1;
`;

const SubtitleStyled = styled.div`
  color: rgb(108, 108, 108);
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0;
  margin-bottom: 8px;

  & span {
    color: var(--main-color);
  }
`;

const AdvertInfoStyled = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
  display: flex;
  justify-content: space-between;
`;

const AdvertInfoItemStyled = styled.div`
  display: flex;
  color: rgb(108, 108, 108);
  gap: 8px;
`;
const AdvertInfoItemValueStyled = styled.div`
  color: rgb(46, 46, 46);
`;

const DescriptionWrapperStyled = styled.div`
  margin-top: 20px;
`;

const DescriptionStyled = styled.div`
  color: rgb(108, 108, 108);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 180%;
  letter-spacing: 0;
`;

export const SectionTitleStyled = styled.h3`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0;
  margin-bottom: 24px;
`;

const DetailsActionStyled = styled.div<{ $active?: boolean }>`
  padding: 12px;
  color: ${(props) => (props.$active ? "var(--main-color)" : "rgb(108, 108, 108);")};
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
  border: 1px solid ${(props) => (props.$active ? "var(--main-color)" : "rgb(219, 219, 219)")};
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
`;

const DetailsWrapperStyled = styled.div``;
const DetailsSwitcherWrapperStyled = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
  margin-top: 40px;
`;

export const CarPage = () => {
  const params = useParams();
  const { t } = useTranslation();

  const [car, setCar] = useState<PreviewCarItem | null>(null);
  const [detail, setDetail] = useState("general");
  const [is404, setIs404] = useState(false);
  useEffect(() => {
    if (params.id) {
      axios
        .get(`/car/${params.id}`)
        .then((res) => res.data)
        .then((data) => {
          setCar(data);
          const cookieValue = document.cookie
            .split("; ")
            .find((row) => row.startsWith(params.id as string));

          if (cookieValue) {
            return;
          }
          document.cookie = `${params.id}=1; SameSite=None; Secure; max-age=${60 * 60 * 24 * 5}`;
          axios.post(`/car/update-view/${params.id}`);
        })
        .catch((e) => {
          if (e.response.status === 404) {
            setIs404(true);
          }
        });
    }
  }, [params.id]);

  const handleChangeDetails = (value: string) => () => {
    setDetail(value);
  };

  if (is404) {
    return <NotFound text="preview.car-not-found" />;
  }

  return (
    <>
      {car && (
        <CarPageContainerStyled>
          <CaRBreadcrumbs
            brand={car.brand}
            model={car.modelView}
            brandSearchName={car.brand}
            modelSearchName={car.model}
          />
          <CarPageWrapper>
            <CarWrapperStyled>
              <TitleWrapperStyled>
                <TitleStyled>
                  {car.brand.toUpperCase()} {car.modelView.toUpperCase()}
                </TitleStyled>
                <span>{car.year}</span>
              </TitleWrapperStyled>
              <SubtitleStyled>
                {car.generationView} <span>{car.modificationView.toUpperCase()}</span>
              </SubtitleStyled>
              <InfoItems car={car} />
              <PreviewSlider car={car} />
              <AdvertInfoStyled>
                <AdvertInfoItemStyled>
                  {t("preview.published")}:
                  <AdvertInfoItemValueStyled>
                    {new Date(car.createdAt).toLocaleDateString()}
                  </AdvertInfoItemValueStyled>
                  | {t("preview.views")}:
                  <AdvertInfoItemValueStyled>{car.views}</AdvertInfoItemValueStyled>|{" "}
                  {t("preview.saved")}:
                  <AdvertInfoItemValueStyled>{car.likes}</AdvertInfoItemValueStyled>
                </AdvertInfoItemStyled>
                <AdvertInfoItemStyled>
                  {t("preview.advert-id")}:
                  <AdvertInfoItemValueStyled>{car._id}</AdvertInfoItemValueStyled>
                </AdvertInfoItemStyled>
              </AdvertInfoStyled>
              <DescriptionWrapperStyled>
                <SectionTitleStyled>{t("preview.description")}</SectionTitleStyled>
                <DescriptionStyled>{car.description}</DescriptionStyled>
              </DescriptionWrapperStyled>
              <DetailsWrapperStyled>
                <DetailsSwitcherWrapperStyled>
                  <DetailsActionStyled
                    $active={detail === "general"}
                    onClick={handleChangeDetails("general")}
                  >
                    {t("preview.general")}
                  </DetailsActionStyled>
                  <DetailsActionStyled
                    $active={detail === "additional"}
                    onClick={handleChangeDetails("additional")}
                  >
                    {t("preview.additional")}
                  </DetailsActionStyled>
                </DetailsSwitcherWrapperStyled>

                {detail === "general" ? <GeneralInfo car={car} /> : <AdditionalInfo car={car} />}
              </DetailsWrapperStyled>
            </CarWrapperStyled>
            <ContantWrapperStyled>
              <Contacts car={car} />
            </ContantWrapperStyled>
          </CarPageWrapper>
          <Recommendation carId={car._id} />
        </CarPageContainerStyled>
      )}
    </>
  );
};

export enum AuthRoutesPath {
  index = "/auth/*",
  auth = "/auth",
  loginPath = "/auth/login",
  resetPassword = "/reset-password",
}

export enum UserRoutesPath {
  user = "/user",
  index = "/user/*",
  cars = "/cars",
  userCar = "/user/car",
  phoneVerification = "/phone-verification",
  settings = "/settings",
}

export enum SearchRoutesPath {
  index = "/search/*",
  search = "/search",
  advanced = "/advanced",
  searchAdvanced = "/search/advanced",
}

export enum CarRoutesPath {
  carID = "/car/:id",
  index = "/car/*",
  car = "/car",
  add = "/add",
  edit = "/edit",
  editID = "/edit/:id",
  favourite = "/favourite",
}

export enum AdminRoutesPath {
  admin = "/admin",
  index = "/admin/*",
  search = "/search",
  edit = "/edit",
  editID = "/edit/:id",
  users = "/users",
  user = "/user",
  userID = "/user/:id",
}

export enum NotFoundRoutesPath {
  notFound = "/404",
}

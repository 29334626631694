import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { BTNTypes, ButtonStyled } from "../../components/common/Button";
import { SearchRoutesPath } from "../../routes";

const ContainerStyled = styled.div`
  margin-top: 45px;
`;

const TitleStyled = styled.h1`
  color: var(--main-color);
  font-size: 46px;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 10px;
  font-family: Roboto;
  text-align: center;
  & span {
    color: rgb(191, 191, 191);
  }
`;

const DescriptionStyled = styled.div`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
`;

const ActionWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  & > a {
    width: 100%;
    & > button {
      width: 100%;
    }
  }
`;

export const NotFound: React.FC<{ text?: string }> = ({ text }) => {
  const { t } = useTranslation();
  return (
    <ContainerStyled>
      <TitleStyled>
        4<span>0</span>4
      </TitleStyled>
      <DescriptionStyled>{t(text || "404.description")}</DescriptionStyled>
      <ActionWrapperStyled>
        <NavLink to="/">
          <ButtonStyled $type={BTNTypes.PRIMARY}>{t("footer.home")}</ButtonStyled>
        </NavLink>
        <NavLink to={SearchRoutesPath.search}>
          <ButtonStyled $type={BTNTypes.SECONDARY}>{t("footer.search")}</ButtonStyled>
        </NavLink>
      </ActionWrapperStyled>
    </ContainerStyled>
  );
};

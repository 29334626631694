import { Alert, Checkbox, Flex, Form, Input } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import styled, { css } from "styled-components";

import { PHONE } from "../../../constants/AdditionalInfo";
import { useAppDispatch, useAppSelector } from "../../../helpers/store.hook";
import { LabelStyled } from "../../car/components/addCar/form";
import { BTNTypes, ButtonStyled } from "../../common/Button";
import { AuthActions } from "../redux/auth.slice";
import { LoginThunk } from "../redux/auth.thunks";

export const inputStyles = css`border: 1px solid
  border-radius: 8px;
  width: 100%;
  background: rgb(255, 255, 255);
  padding: 16px;
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-transform: capitalize;
  `;

export const InputPasswordStyled = styled(Input.Password)`
  ${inputStyles}
`;
export const InputStyled = styled(Input)`
  ${inputStyles}
`;

export const MaskedInputStyled = styled(InputMask)`
  ${inputStyles}
  outline: none;
`;

export const CheckboxStyled = styled(Checkbox)`
  color: rgb(0, 0, 0);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
`;

export const ResetPasswordStyled = styled.div`
  color: rgb(116, 99, 232);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-decoration-line: underline;
  cursor: pointer;
`;

export const LoginForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isPending = useAppSelector((state) => state.auth.isPending);
  const loginError = useAppSelector((state) => state.auth.loginError);
  const [phone, setPhone] = useState("");
  const onFinish = (values: { phone: string; password: string; remember: boolean }) => {
    dispatch(
      LoginThunk({
        userData: {
          password: values.password,
          phone: values.phone.replace(/\s/g, "").replace("+", ""),
          remember: values.remember,
        },
      }),
    );
  };
  const handleReset = () => {
    dispatch(AuthActions.openReset());
  };

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 17) {
      form.setFieldsValue({ phone: phone });
      return;
    }

    setPhone(e.target.value);
    form.setFieldsValue({ phone: e.target.value });
  };

  return (
    <Form
      name="login"
      layout="vertical"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      disabled={isPending}
      form={form}
    >
      {loginError && <Alert message={t(loginError)} type="error" />}
      <Form.Item
        name={PHONE}
        label={<LabelStyled>{t("auth.phone.label")}</LabelStyled>}
        validateTrigger="onBlur"
        rules={[
          { required: true, message: t("auth.phone.error") },
          { max: 17, message: t("auth.phone.error-format") },
          { min: 12, message: t("auth.phone.error-min-length", { length: 12 }) },
        ]}
      >
        <MaskedInputStyled
          mask="+380 99 999 99 99"
          value={phone}
          alwaysShowMask={false}
          onChange={handleChangePhone}
          placeholder="+380  "
          maskChar=""
        >
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (props) => <Input {...props} />
          }
        </MaskedInputStyled>
      </Form.Item>

      <Form.Item
        label={<LabelStyled>{t("auth.password.label")}</LabelStyled>}
        name="password"
        rules={[{ required: true, message: t("auth.password.error") }]}
      >
        <InputPasswordStyled />
      </Form.Item>
      <Form.Item>
        <Flex justify="space-between" align="center">
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <CheckboxStyled>{t("auth.remember.label")}</CheckboxStyled>
          </Form.Item>
          <ResetPasswordStyled onClick={handleReset}>
            {t("auth.password.forgot")}
          </ResetPasswordStyled>
        </Flex>
      </Form.Item>
      <Form.Item>
        <ButtonStyled $type={BTNTypes.PRIMARY} block type="primary" htmlType="submit">
          {t("auth.login.label")}
        </ButtonStyled>
      </Form.Item>
    </Form>
  );
};

import { Button } from "antd";
import styled from "styled-components";

export enum BTNTypes {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export const ButtonStyled = styled(Button)<{ $type: BTNTypes; $hidden?: boolean }>`
  visibility: ${({ $hidden }) => ($hidden ? "hidden" : "visible")};
  color: ${({ $type }) => ($type === BTNTypes.PRIMARY ? "rgb(255, 255, 255)" : "#3D3D3D")};
  font-family: Roboto;
  font-size: 16px;
  height: auto;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0;
  text-align: center;
  border-color: ${({ $type }) =>
    $type === BTNTypes.PRIMARY ? "var(--main-color)" : "rgb(219, 219, 219)"} !important;
  padding: 16px;
  border-radius: 8px;

  background-color: ${({ $type }) =>
    $type === BTNTypes.PRIMARY ? "var(--main-color)" : "rgb(255, 255, 255)"};

  &:hover {
    border: 1px solid rgb(116, 99, 232) !important;
    background: rgba(116, 99, 232, 0.35) !important;
    color: #fff !important;
  }
  &:disabled {
    background: rgba(255, 255, 255, 1) !important;
    color: rgba(0, 0, 0, 0.25) !important;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
  }
`;

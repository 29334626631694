import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { PhoneConfirm } from "../../components/common/phoneConfirm/phoneConfirm";
import { SettingsForm } from "../../components/user/settings/components/settingsForm";
import { SettingsPhoto } from "../../components/user/settings/components/settingsPhoto";

const ContainerStyled = styled.div`
  margin-top: 32px;
`;
const WrapperStyled = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  gap: 24px;
`;

const TitleStyled = styled.h1`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0;
  text-align: center;
`;

export const Settings = () => {
  const { t } = useTranslation();
  return (
    <ContainerStyled>
      <PhoneConfirm />
      <TitleStyled>{t("user.settings.main-title")}</TitleStyled>
      <WrapperStyled>
        <SettingsPhoto />
        <SettingsForm />
      </WrapperStyled>
    </ContainerStyled>
  );
};

import { Button } from "antd";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components";

import defaultUserIcon from "./defaultUser.jpg";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { PreviewCarItem } from "../../../../pages/car/preview/PreviewCar";
import { manageFavorite } from "../../../auth/redux/auth.thunks";
import { BTNTypes, ButtonStyled } from "../../../common/Button";

const ContactWrapperStyled = styled.div`
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  background: rgb(255, 255, 255);
  width: 470px;
`;

const PriceWrapperStyled = styled.div`
  padding: 16px;
  border-bottom: 1px solid rgb(219, 219, 219);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const LabelStyled = styled.div`
  color: rgb(108, 108, 108);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  margin-bottom: 8px;
`;

const PriceStyled = styled.div`
  color: rgb(116, 99, 232);
  font-family: Roboto;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0;
`;

const UserInfoWrapperStyled = styled.div`
  padding: 16px;
`;

const UserInfoStyled = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 12px;
`;

const UserAvatarStyled = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

const UserNameStyled = styled.div`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
`;

const ContantActionsWrapperStyled = styled.div`
  padding: 16px;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid rgb(219, 219, 219);
  & a {
    text-decoration: none;
  }
  & button {
    display: flex;
    align-items: center;
    min-width: 180px;
    text-decoration: none;

    & svg {
      margin-right: 8px;
    }
  }
`;

const LocationTextStyled = styled.div`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;

  display: flex;
  align-items: center;

  & svg {
    margin-right: 8px;
  }
`;

const LocationWrapperStyled = styled.div`
  padding: 16px;
`;

const TitleActionsWrapperStyled = styled.div``;

const ActiveStyle = css`
  border-color: var(--main-color) !important;
  background-color: var(--main-color) !important;

  & svg * {
    fill: white;
  }

  &:hover {
    border-color: rgb(219, 219, 219) !important;
    background-color: red !important;

    & svg * {
      fill: black;
    }
  }
`;

const InActiveStyle = css`
  &:hover {
    border-color: var(--main-color) !important;
    background-color: var(--main-color) !important;

    & svg * {
      fill: white;
    }
  }
`;

const FavoriteButtonStyled = styled(Button)<{ $active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;

  ${(props) => (props.$active ? ActiveStyle : InActiveStyle)}
`;

const PriceContainerStyled = styled.div``;

const ActionsContainerStyled = styled.div`
  display: flex;
  gap: 8px;
`;

const UserDescriptionStyled = styled.div`
  margin-top: 16px;
  color: rgb(108, 108, 108);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0;
`;

interface ContactsProps {
  car: PreviewCarItem;
}

export const Contacts: React.FC<ContactsProps> = ({ car }) => {
  const { t } = useTranslation();
  const params = useParams();
  const [isPhoneHide, setIsPhoneHide] = useState(true);
  const dispatch = useAppDispatch();
  const favorite = useAppSelector((state) => state.auth.favorite);
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const handleFaivorite = () => {
    if (params.id && isLogin) {
      const isNew = !favorite.some((item) => item === params?.id);

      dispatch(manageFavorite({ id: params.id, isNew }));
    }
  };

  const handlePhone = () => {
    if (isPhoneHide) {
      setIsPhoneHide(false);
    }
  };
  return (
    <ContactWrapperStyled>
      <PriceWrapperStyled>
        <PriceContainerStyled>
          <LabelStyled>{t("price")}</LabelStyled>
          <PriceStyled>$ {car.price.toLocaleString("en-US")}</PriceStyled>
        </PriceContainerStyled>
        <ActionsContainerStyled>
          {isLogin && (
            <TitleActionsWrapperStyled>
              <FavoriteButtonStyled
                onClick={handleFaivorite}
                $active={!!params.id && favorite?.includes(params.id)}
              >
                <svg
                  width="19.000000"
                  height="17.176758"
                  viewBox="0 0 19 17.1768"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="favorite"
                    d="M9.5 17.17L8.39 16.18C6.73 14.68 5.36 13.38 4.28 12.3C3.19 11.22 2.34 10.26 1.7 9.42C1.07 8.57 0.62 7.81 0.37 7.11C0.12 6.42 0 5.71 0 5C0 3.58 0.47 2.39 1.43 1.43C2.39 0.47 3.58 0 5 0C5.87 0 6.69 0.2 7.47 0.61C8.25 1.02 8.92 1.6 9.5 2.36C10.07 1.6 10.74 1.02 11.52 0.61C12.3 0.2 13.12 0 14 0C15.41 0 16.6 0.47 17.56 1.43C18.52 2.39 19 3.58 19 5C19 5.71 18.87 6.42 18.62 7.11C18.37 7.81 17.92 8.57 17.29 9.42C16.65 10.26 15.8 11.22 14.72 12.3C13.64 13.38 12.26 14.68 10.6 16.18L9.5 17.17Z"
                    fill="#1C1B1F"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                  />
                </svg>
              </FavoriteButtonStyled>
            </TitleActionsWrapperStyled>
          )}
          <TitleActionsWrapperStyled>
            <FavoriteButtonStyled $active={false}>
              <svg
                width="17.000000"
                height="19.000000"
                viewBox="0 0 17 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="share"
                  d="M14.3 19C13.55 19 12.92 18.73 12.39 18.21C11.87 17.69 11.61 17.05 11.61 16.3C11.61 16.2 11.65 15.96 11.71 15.57L4.6 11.39C4.36 11.64 4.08 11.83 3.74 11.98C3.41 12.12 3.06 12.19 2.68 12.19C1.93 12.19 1.3 11.92 0.78 11.4C0.26 10.87 0 10.24 0 9.5C0 8.75 0.26 8.12 0.78 7.59C1.3 7.07 1.93 6.8 2.68 6.8C3.06 6.8 3.41 6.87 3.74 7.01C4.08 7.16 4.36 7.35 4.6 7.6L11.71 3.42C11.67 3.3 11.65 3.18 11.63 3.07C11.62 2.95 11.61 2.82 11.61 2.69C11.61 1.94 11.87 1.3 12.4 0.78C12.92 0.26 13.56 0 14.31 0C15.05 0 15.69 0.26 16.21 0.78C16.73 1.3 17 1.94 17 2.69C17 3.44 16.73 4.07 16.21 4.6C15.69 5.12 15.05 5.38 14.3 5.38C13.92 5.38 13.57 5.31 13.24 5.16C12.91 5.02 12.63 4.82 12.39 4.57L5.28 8.76C5.32 8.88 5.34 9 5.36 9.12C5.37 9.24 5.38 9.36 5.38 9.5C5.38 9.63 5.37 9.75 5.36 9.87C5.34 9.99 5.32 10.11 5.28 10.23L12.39 14.42C12.63 14.17 12.91 13.97 13.24 13.83C13.57 13.68 13.92 13.61 14.3 13.61C15.05 13.61 15.69 13.87 16.21 14.4C16.73 14.92 17 15.56 17 16.3C17 17.05 16.73 17.69 16.21 18.21C15.68 18.73 15.05 19 14.3 19Z"
                  fill="#1C1B1F"
                  fillOpacity="1.000000"
                  fillRule="nonzero"
                />
              </svg>
            </FavoriteButtonStyled>
          </TitleActionsWrapperStyled>
        </ActionsContainerStyled>
      </PriceWrapperStyled>
      <UserInfoWrapperStyled>
        <LabelStyled>{t("seller")}</LabelStyled>
        <UserInfoStyled>
          <UserAvatarStyled>
            <img src={car.user.userPhoto || defaultUserIcon} alt="avatar" />
          </UserAvatarStyled>
          <UserNameStyled>{car.user.name}</UserNameStyled>
        </UserInfoStyled>
        {car.user.description && (
          <UserDescriptionStyled>{car.user.description}</UserDescriptionStyled>
        )}
      </UserInfoWrapperStyled>
      <ContantActionsWrapperStyled>
        <WrapNumber isShow={isPhoneHide} phone={car.user.phone}>
          <ButtonStyled $type={BTNTypes.PRIMARY} onClick={handlePhone}>
            <svg
              width="14.166016"
              height="14.167969"
              viewBox="0 0 14.166 14.168"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="call"
                d="M13.28 14.16C11.71 14.16 10.13 13.8 8.55 13.07C6.96 12.33 5.5 11.31 4.17 9.98C2.85 8.65 1.82 7.19 1.09 5.61C0.36 4.03 0 2.45 0 0.88C0 0.63 0.08 0.42 0.25 0.25C0.41 0.08 0.62 0 0.87 0L3.59 0C3.8 0 3.98 0.07 4.15 0.2C4.31 0.34 4.41 0.51 4.45 0.71L4.93 3.16C4.96 3.39 4.96 3.58 4.91 3.75C4.86 3.91 4.78 4.05 4.66 4.16L2.73 6.03C3.04 6.6 3.39 7.14 3.79 7.64C4.19 8.15 4.62 8.63 5.08 9.09C5.54 9.55 6.03 9.98 6.54 10.37C7.06 10.76 7.61 11.12 8.21 11.46L10.08 9.58C10.21 9.44 10.37 9.34 10.56 9.29C10.74 9.24 10.93 9.22 11.13 9.25L13.45 9.72C13.66 9.78 13.83 9.89 13.96 10.04C14.09 10.2 14.16 10.38 14.16 10.58L14.16 13.29C14.16 13.54 14.08 13.75 13.91 13.91C13.74 14.08 13.53 14.16 13.28 14.16Z"
                fill="#2E2E2E"
                fillOpacity="1.000000"
                fillRule="nonzero"
              />
            </svg>

            {isPhoneHide ? t("call") : car.user.phone.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
          </ButtonStyled>
        </WrapNumber>
        <ButtonStyled $type={BTNTypes.SECONDARY}>
          <svg
            width="11.666687"
            height="13.541687"
            viewBox="0 0 11.6667 13.5417"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="flag"
              d="M0 13.54L0 0L6.74 0L7.08 1.66L11.66 1.66L11.66 9.16L6.58 9.16L6.25 7.5L1.25 7.5L1.25 13.54L0 13.54Z"
              fill="#2E2E2E"
              fillOpacity="1.000000"
              fillRule="nonzero"
            />
          </svg>
          {t("report")}
        </ButtonStyled>
      </ContantActionsWrapperStyled>
      <LocationWrapperStyled>
        <LabelStyled>{t("location")}</LabelStyled>
        <LocationTextStyled>
          <svg
            width="15.095947"
            height="19.009521"
            viewBox="0 0 15.0959 19.0095"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="location_on"
              d="M7.54 9.36C8.04 9.36 8.47 9.18 8.82 8.83C9.17 8.47 9.35 8.05 9.35 7.55C9.35 7.05 9.17 6.63 8.82 6.27C8.46 5.92 8.04 5.75 7.54 5.75C7.04 5.75 6.62 5.92 6.27 6.28C5.91 6.63 5.74 7.06 5.74 7.55C5.74 8.05 5.91 8.48 6.27 8.83C6.62 9.18 7.05 9.36 7.54 9.36ZM7.54 19C5.03 16.82 3.14 14.79 1.88 12.92C0.62 11.04 0 9.31 0 7.74C0 5.44 0.74 3.57 2.23 2.14C3.73 0.71 5.5 0 7.54 0C9.59 0 11.36 0.71 12.85 2.14C14.34 3.57 15.09 5.44 15.09 7.74C15.09 9.31 14.46 11.04 13.2 12.92C11.95 14.79 10.06 16.82 7.54 19Z"
              fill="#7463E8"
              fillOpacity="1.000000"
              fillRule="nonzero"
            />
          </svg>
          {car.region} {t("region")}, {car.city ? `${t("city-short")} ${car.city}` : ""}
        </LocationTextStyled>
      </LocationWrapperStyled>
    </ContactWrapperStyled>
  );
};

const WrapNumber: React.FC<{ isShow: boolean; phone: string; children: ReactNode }> = ({
  isShow,
  phone,
  children,
}) => {
  if (!isShow) {
    return <a href={`tel:${phone}`}>{children}</a>;
  }
  return <>{children}</>;
};

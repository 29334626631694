import { Tooltip } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { PhoneConfirmPopup } from "./phoneConfirmPopup";
import { useAppSelector } from "../../../helpers/store.hook";
import { BTNTypes, ButtonStyled } from "../Button";

const ContainerStyled = styled.div`
  border-radius: 8px;
  background: rgba(116, 99, 232, 0.15);
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

const TextStyled = styled.div`
  display: flex;
  align-items: center;
  color: rgb(0, 0, 0);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  & svg {
    margin-right: 10px;
  }
`;

const ActionWrapperStyled = styled.div``;

export const PhoneConfirm: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const isPhoneConfirmed = useAppSelector((state) => state.auth.isPhoneVerified);
  if (isPhoneConfirmed) return null;

  const handleConfirm = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <ContainerStyled>
      <TextStyled>
        <Tooltip title={t("phone.description")} color={"var(--main-color)"}>
          <svg
            width="20.000000"
            height="20.000000"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="Vector"
              d="M20 10C20 4.47 15.52 0 10 0C4.47 0 0 4.47 0 10C0 15.52 4.47 20 10 20C15.52 20 20 15.52 20 10ZM10 4.25C10.41 4.25 10.75 4.58 10.75 5L10.75 11C10.75 11.41 10.41 11.75 10 11.75C9.58 11.75 9.25 11.41 9.25 11L9.25 5C9.25 4.58 9.58 4.25 10 4.25ZM10 15C10.55 15 11 14.55 11 14C11 13.44 10.55 13 10 13C9.44 13 9 13.44 9 14C9 14.55 9.44 15 10 15Z"
              fill="#7463E8"
              fillOpacity="1.000000"
              fillRule="evenodd"
            />
          </svg>
        </Tooltip>
        {t("phone.not-confirm")}
      </TextStyled>
      <ActionWrapperStyled>
        <ButtonStyled $type={BTNTypes.PRIMARY} onClick={handleConfirm}>
          {t("phone.confirm")}
        </ButtonStyled>
      </ActionWrapperStyled>
      <PhoneConfirmPopup isOpen={isOpen} onClose={handleClose} />
    </ContainerStyled>
  );
};

import qs from "qs";
import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { Brands, CarModel, FromToEnum } from "../../components/filters/filters.types";
import { FiltersActions } from "../../components/filters/redux/filters.slice";
import { getGenerationsThunk, getModelsThunk } from "../../components/filters/redux/filters.thunks";
import { SearchFilter } from "../../components/search/components/searchFilter";
import { SearchList } from "../../components/search/components/searchList";
import { AdditionalInfoSearchSelectedDataKeys } from "../../constants/AdditionalInfo";
import { useAppDispatch } from "../../helpers/store.hook";

const SearchPageStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  gap: 50px;
`;

const SearchListWrapperStyled = styled.div`
  max-width: 80%;
  width: 100%;
`;

const SearcFilterWrapperStyled = styled.div`
  max-width: 20%;
  min-width: 270px;
  width: 100%;
`;

export const SearchPage = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const parseSearchParams = useCallback(async () => {
    let brand: string | null = null;
    let model: string | null = null;
    let generation: { [key: string]: string[] } | null = null;
    for (const value of searchParams.keys()) {
      if (value === "page" || value === "limit") {
        // console.log("page", searchParams.get(value));
      }
      if (value === "brand") {
        brand = searchParams.get("brand");
      }
      if (value === "model") {
        model = searchParams.get("model");
      }

      if (value === "generation") {
        generation = qs.parse(searchParams.get("generation") as string) as unknown as {
          [key: string]: string[];
        };
        console.log("generation", generation);
      }

      if (value === "powerFrom") {
        const powerFrom = searchParams.get("powerFrom");

        dispatch(FiltersActions.setPowers({ type: FromToEnum.FROM, value: Number(powerFrom) }));
      }
      if (value === "powerTo") {
        const powerTo = searchParams.get("powerTo");

        dispatch(FiltersActions.setPowers({ type: FromToEnum.TO, value: Number(powerTo) }));
      }

      if (value === "yearFrom") {
        const yearFrom = searchParams.get("yearFrom");

        dispatch(FiltersActions.setYear({ type: FromToEnum.FROM, value: Number(yearFrom) }));
      }
      if (value === "yearTo") {
        const yearTo = searchParams.get("yearTo");

        dispatch(FiltersActions.setYear({ type: FromToEnum.TO, value: Number(yearTo) }));
      }
      if (value === "mileageFrom") {
        const mileageFrom = searchParams.get("mileageFrom");

        dispatch(FiltersActions.setMileage({ type: FromToEnum.FROM, value: Number(mileageFrom) }));
      }
      if (value === "mileageTo") {
        const mileageTo = searchParams.get("mileageTo");

        dispatch(FiltersActions.setMileage({ type: FromToEnum.TO, value: Number(mileageTo) }));
      }

      if (value === "priceFrom") {
        const priceFrom = searchParams.get("priceFrom");

        dispatch(
          FiltersActions.setPrice({
            type: FromToEnum.FROM,
            value: Number(priceFrom as unknown as number),
          }),
        );
      }
      if (value === "priceTo") {
        const priceTo = searchParams.get("priceTo");

        dispatch(
          FiltersActions.setPrice({
            type: FromToEnum.TO,
            value: Number(priceTo as unknown as number),
          }),
        );
      }
      if (value === "sort") {
        console.log("changeSort", searchParams.get("sort"));
      }
      if (value === "period") {
        dispatch(FiltersActions.setPeriod(searchParams.get("period") || "all"));
      }

      if (value === AdditionalInfoSearchSelectedDataKeys.FUEL) {
        const fuel: string[] = searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.FUEL) || [];

        dispatch(FiltersActions.setFuels(fuel));
      }
      if (value === AdditionalInfoSearchSelectedDataKeys.BODY_TYPE) {
        const bodyType: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.BODY_TYPE) || [];
        dispatch(FiltersActions.setBodyTypes(bodyType));
      }
      if (value === AdditionalInfoSearchSelectedDataKeys.DRIVE_TYPE) {
        const bodyType: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.DRIVE_TYPE) || [];
        dispatch(FiltersActions.setDriveTypes(bodyType));
      }

      if (value === AdditionalInfoSearchSelectedDataKeys.OTHER) {
        const other: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.OTHER) || [];

        dispatch(FiltersActions.setOthers(other));
      }

      if (value === AdditionalInfoSearchSelectedDataKeys.COLOR) {
        const colors: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.COLOR) || [];

        dispatch(FiltersActions.setColors(colors));
      }
      if (value === AdditionalInfoSearchSelectedDataKeys.GEAR) {
        const gear: string[] = searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.GEAR) || [];

        dispatch(FiltersActions.setGears(gear));
      }
      if (value === AdditionalInfoSearchSelectedDataKeys.MULTIMEDIA) {
        const multimedia: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.MULTIMEDIA) || [];
        dispatch(FiltersActions.setMultimedias(multimedia));
      }
      if (value === AdditionalInfoSearchSelectedDataKeys.AIRBAG) {
        const airbag: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.AIRBAG) || [];
        dispatch(FiltersActions.setAirbags(airbag));
      }

      if (value === AdditionalInfoSearchSelectedDataKeys.SECURITY) {
        const security: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.SECURITY) || [];
        dispatch(FiltersActions.setSecurities(security));
      }
      if (value === AdditionalInfoSearchSelectedDataKeys.SALON) {
        const salon: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.SALON) || [];
        dispatch(FiltersActions.setSalons(salon));
      }
      if (value === AdditionalInfoSearchSelectedDataKeys.OPTIC) {
        const optics: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.OPTIC) || [];
        dispatch(FiltersActions.setOptics(optics));
      }
      if (value === AdditionalInfoSearchSelectedDataKeys.LIGHT) {
        const lights: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.LIGHT) || [];
        dispatch(FiltersActions.setLights(lights));
      }
      if (value === "useInfo") {
        const useInfo: string[] = searchParams.getAll("useInfo") || [];
        dispatch(FiltersActions.setUseInfos(useInfo));
      }
      if (value === "afterAccident") {
        const afterAccident = searchParams.get("afterAccident");
        dispatch(FiltersActions.setAfterAccident(afterAccident === "true"));
      }
    }

    if (brand) {
      await dispatch(getModelsThunk({ searchName: brand } as unknown as Brands));
    }
    if (model) {
      await dispatch(getGenerationsThunk({ searchName: model } as unknown as CarModel));
    }

    if (generation) {
      dispatch(FiltersActions.setGenerations(generation));
    }
  }, [dispatch, searchParams]);

  useEffect(() => {
    parseSearchParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchPageStyled>
      <SearcFilterWrapperStyled>
        <SearchFilter />
      </SearcFilterWrapperStyled>
      <SearchListWrapperStyled>
        <SearchList />
      </SearchListWrapperStyled>
    </SearchPageStyled>
  );
};

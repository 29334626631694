import { Form, Modal, Radio, RadioChangeEvent, Rate } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { BTNTypes, ButtonStyled } from "../../../common/Button";

const ModalWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
`;

const TitleStyled = styled.div`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0;
  margin-bottom: 20px;
  text-align: center;
`;

const RadioGroupStyled = styled(Radio.Group)``;

const ItemStyled = styled(Radio.Button)`
  background: rgb(240, 240, 240);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  height: 47px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    background: var(--main-color);
    border-color: var(--main-color);
  }
  &:hover {
    color: #fff;
    background: var(--main-color) !important;
    border-color: var(--main-color) !important;
  }
`;

const RatingWrapperStyled = styled.div`
  margin-top: 18px;
`;

const RatingTitleStyled = styled.div`
  color: rgb(0, 0, 0);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 15px;
`;

const FormItemStyld = styled(Form.Item)`
  & .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
`;

const ActionWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & button {
    width: 100%;
  }
`;

export const RemoveConfirmPopUp: React.FC<{
  isOpen: boolean;
  carId?: string;
  onClose: () => void;
  onConfirm: (carId: string, data: { rating: number; selled: boolean }) => void;
}> = ({ carId, isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleAnswer = ({ target: { value } }: RadioChangeEvent) => {
    console.log("value", value);
  };

  const handleRate = (value: number) => {
    console.log("value", value);
    form.setFieldsValue({ rating: value });
  };

  const handleFinish = (values: { selled: boolean; rating: number }) => {
    if (carId) onConfirm(carId, values);
  };
  return (
    <>
      <Modal
        open={isOpen}
        onCancel={onClose}
        footer={null}
        width={500}
        style={{ maxWidth: "1700px" }}
        centered
      >
        <Form name="delete_car" onFinish={handleFinish} form={form}>
          <ModalWrapperStyled>
            <TitleStyled>{t("user.car.delete-confirm")}</TitleStyled>
            <FormItemStyld
              name="selled"
              rules={[{ required: true, message: t("user.car.confirm-error") }]}
            >
              <RadioGroupStyled buttonStyle="solid">
                <ItemStyled value={true} onChange={handleAnswer}>
                  {t("user.car.yes")}
                </ItemStyled>
                <ItemStyled value={false} onChange={handleAnswer}>
                  {t("user.car.no")}
                </ItemStyled>
              </RadioGroupStyled>
            </FormItemStyld>
            <FormItemStyld
              name="rating"
              rules={[{ required: true, message: t("user.car.confirm-error") }]}
            >
              <RatingWrapperStyled>
                <RatingTitleStyled>{t("user.car.rating")}</RatingTitleStyled>
                <Rate onChange={handleRate} />
              </RatingWrapperStyled>
            </FormItemStyld>
            <ActionWrapperStyled>
              <ButtonStyled $type={BTNTypes.PRIMARY} htmlType="submit">
                {t("user.car.confirm")}
              </ButtonStyled>
              <ButtonStyled $type={BTNTypes.SECONDARY} onClick={onClose}>
                {t("user.car.cancel")}
              </ButtonStyled>
            </ActionWrapperStyled>
          </ModalWrapperStyled>
        </Form>
      </Modal>
    </>
  );
};

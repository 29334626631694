import "./App.css";
import "./i18n/config";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import styled from "styled-components";

import { ResetForm } from "./components/auth/login/resetPassword";
import { getCurrentUser } from "./components/auth/redux/auth.thunks";
import { ScrollToTop } from "./components/common/ScrollToTop";
import { getBrandsThunk } from "./components/filters/redux/filters.thunks";
import { Footer } from "./components/footer/footer";
import { Header } from "./components/header/header";
import { useAppDispatch, useAppSelector } from "./helpers/store.hook";
import { NotFound } from "./pages/404/404";
import { MainPage } from "./pages/main";
import {
  AdminRoutesPath,
  AuthRoutesPath,
  CarRoutesPath,
  NotFoundRoutesPath,
  SearchRoutesPath,
  UserRoutesPath,
} from "./routes";
import { AdminRoutes } from "./routes/admin/adminRoutes";
import { Auth } from "./routes/auth/authRoutes";
import { CarRoutes } from "./routes/car/carRoutes";
import { SearchRoutes } from "./routes/search/searchRoutes";
import { UserRoutes } from "./routes/user/userRoutes";

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("G-4PV26WL71Y");
}

export const ContainerAppStyled = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  flex-grow: 1;
`;

function App() {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.auth.loading);
  useEffect(() => {
    dispatch(getCurrentUser());
    dispatch(getBrandsThunk());
  }, [dispatch]);

  if (loading) {
    return <>Loading</>;
  }
  return (
    <BrowserRouter>
      <Header />
      <ScrollToTop />
      <ContainerAppStyled>
        <ResetForm />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path={AuthRoutesPath.index} element={<Auth />} />
          <Route path={UserRoutesPath.index} element={<UserRoutes />} />
          <Route path={SearchRoutesPath.index} element={<SearchRoutes />} />
          <Route path={CarRoutesPath.index} element={<CarRoutes />} />
          <Route path={AdminRoutesPath.index} element={<AdminRoutes />} />
          <Route path={NotFoundRoutesPath.notFound} element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ContainerAppStyled>
      <Footer />
    </BrowserRouter>
  );
}

export default App;

import { Button, Carousel } from "antd";
import { CarouselRef } from "antd/es/carousel";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import axios from "../../../../helpers/interseptor";
import { CarRoutesPath, SearchRoutesPath } from "../../../../routes";
import { CarItem } from "../../../car/CarTypes";
import { BTNTypes, ButtonStyled } from "../../../common/Button";
import { SearchCart } from "../../../search/components/searchCart";

const RandomCarWrapperStyle = styled.div<{ $background: string }>`
  background: url(${(props) => props.$background}) no-repeat center center;
  padding: 64px;
  width: 100%;
  display: flex;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
`;

const DetailsWrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
  & a > button {
    padding: 16px;
    height: auto;
  }
`;

const CarWrapperStyle = styled.div`
  width: 390px;
  z-index: 2;
  position: relative;
  & > .ant-card {
    width: 470px !important;
  }
`;

const TitleStyle = styled.h4`
  color: rgb(255, 255, 255);
  font-family: Roboto;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0;
  margin-bottom: 16px;
`;

const DescriptionStyle = styled.div`
  /* 16 */
  color: rgb(255, 255, 255);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0%;
  text-align: left;
  opacity: 0.5;
`;

const InfoStyle = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

const InfoItemStyled = styled.div``;
const InfoItemTitleStyled = styled.div`
  color: rgb(255, 255, 255);
  font-family: Roboto;
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0;
`;

const InfoItemSubTitleStyled = styled.div`
  /* 20 */
  color: rgb(255, 255, 255);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0%;
  text-align: left;
`;

const InfoItemDesctiptionStyled = styled.div`
  color: rgb(255, 255, 255);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
  opacity: 0.5;
`;

const DividerStyled = styled.div`
  margin: 0 20px;
  background: linear-gradient(
    351deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(153, 153, 153, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  width: 1px;
`;

const CarouselControlStyled = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
`;

const CarouselInfoStyled = styled.div`
  color: rgb(255, 255, 255);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  & span {
    font-size: 32px;
  }
`;

const CarouselActionsStyled = styled.div`
  display: flex;
  gap: 8px;
`;

const ActionStyled = styled(Button)<{ $isActive: boolean; $type: string }>`
  padding: 12px 24px;
  color: ${({ $isActive }) => ($isActive ? "#2E2E2E" : "#FFFFFF")};
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
  height: 100%;
  border-radius: 8px;
  border: ${({ $isActive }) => ($isActive ? "1px solid rgba(255, 255, 255, 0.05)" : "none")};

  background: ${({ $isActive }) =>
    $isActive ? "rgb(255, 255, 255)" : "rgba(255, 255, 255, 0.05)"};

  & svg {
    margin: ${({ $type }) => ($type === "left" ? "0 8px 0 0" : "0 0 0 8px")};
  }

  & svg * {
    fill: ${({ $isActive }) => ($isActive ? "#2E2E2E" : "#FFFFFF")};
  }

  &:hover {
    border: ${({ $isActive }) => ($isActive ? "1px solid rgb(116, 99, 232)" : "none")} !important;
    background: rgba(116, 99, 232, 0.35) !important;
    color: #fff !important;
  }
`;

export const RandomCar = () => {
  const { t } = useTranslation();
  const [cars, setCar] = useState<CarItem[]>([]);
  const [totalCars, setTotalCars] = useState<number>();
  const [lastDayCars, setLastDayCars] = useState<number>();
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<CarouselRef>(null);

  const handleAction = (type: "next" | "prev") => () => {
    if (type === "next") {
      if (currentSlide === cars.length - 1) return;
      sliderRef.current?.next();
      setCurrentSlide((prev) => prev + 1);
    }
    if (type === "prev") {
      if (currentSlide === 0) return;
      sliderRef.current?.prev();
      setCurrentSlide((prev) => prev - 1);
    }
  };

  useEffect(() => {
    axios
      .get<{ randomCars: CarItem[]; lastDayCars: number; totalCars: number }>("search/stats")
      .then((res) => res.data)
      .then((data) => {
        console.log("data", data);
        if (data.randomCars.length !== 0) setCar(data.randomCars);
        setTotalCars(data.totalCars);
        setLastDayCars(data.lastDayCars);
      });
  }, []);

  return (
    <RandomCarWrapperStyle $background={cars[currentSlide]?.photo[0]}>
      <DetailsWrapperStyle>
        <div>
          <TitleStyle>{t("main.random.title")}</TitleStyle>
          <DescriptionStyle>{t("main.random.description")}</DescriptionStyle>
        </div>
        <div>
          <InfoStyle>
            <InfoItemStyled>
              <InfoItemTitleStyled>{totalCars}</InfoItemTitleStyled>
              <InfoItemSubTitleStyled>{t("main.random.total")}</InfoItemSubTitleStyled>
              <InfoItemDesctiptionStyled>
                {t("main.random.total-description")}
              </InfoItemDesctiptionStyled>
            </InfoItemStyled>
            <DividerStyled></DividerStyled>
            <InfoItemStyled>
              <InfoItemTitleStyled>{lastDayCars}</InfoItemTitleStyled>
              <InfoItemSubTitleStyled>{t("main.random.last-day")}</InfoItemSubTitleStyled>
              <InfoItemDesctiptionStyled>
                {t("main.random.last-day-description")}
              </InfoItemDesctiptionStyled>
            </InfoItemStyled>
          </InfoStyle>

          <NavLink to={SearchRoutesPath.search}>
            <ButtonStyled $type={BTNTypes.PRIMARY}>{t("main.random.action")}</ButtonStyled>
          </NavLink>
        </div>
      </DetailsWrapperStyle>
      <CarWrapperStyle>
        <Carousel
          arrows={false}
          dots={false}
          slidesToShow={1}
          swipe
          swipeToSlide
          style={{ height: "100%" }}
          infinite={false}
          ref={sliderRef}
        >
          {cars.map((car) => (
            <SearchCart key={car._id} car={car} link={`${CarRoutesPath.car}/${car._id}`} />
          ))}
        </Carousel>
        <CarouselControlStyled>
          <CarouselInfoStyled>
            <span>{currentSlide + 1}</span>/ {cars.length}
          </CarouselInfoStyled>
          <CarouselActionsStyled>
            <ActionStyled
              $isActive={currentSlide !== 0}
              $type={"left"}
              onClick={handleAction("prev")}
            >
              <svg
                width="7.274902"
                height="12.000000"
                viewBox="0 0 7.2749 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="chevron_right"
                  d="M2.54 6L7.27 1.27L6 0L0 6L6 12L7.27 10.72L2.54 6Z"
                  fill="#FFFFFF"
                  fillOpacity="1.000000"
                  fillRule="nonzero"
                />
              </svg>
              {t("main.random.prev")}
            </ActionStyled>
            <ActionStyled
              $isActive={currentSlide !== cars.length - 1}
              $type={"right"}
              onClick={handleAction("next")}
            >
              {t("main.random.next")}
              <svg
                width="7.274902"
                height="12.000000"
                viewBox="0 0 7.2749 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="chevron_right"
                  d="M4.72 6L0 1.27L1.27 0L7.27 6L1.27 12L0 10.72L4.72 6Z"
                  fill="#2E2E2E"
                  fillOpacity="1.000000"
                  fillRule="evenodd"
                />
              </svg>
            </ActionStyled>
          </CarouselActionsStyled>
        </CarouselControlStyled>
      </CarWrapperStyle>
    </RandomCarWrapperStyle>
  );
};

import { InputNumber } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { FromTo, FromToEnum } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";

const MileagePickWrapperStyled = styled.div`
  width: 100%;
  margin: 0 auto;
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
`;

const WrapperStyled = styled.div`
  border-radius: 8px;
  padding: 16px;
  background: rgb(255, 255, 255);
`;

const TitleStyled = styled.div`
  color: rgb(46, 46, 46);
  font-size: 18px;
  font-weight: 600;
  line-height: 175%;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InputNumberStyled = styled(InputNumber)<{ onChange: (value: number | null) => void }>`
  flex: 1;
  height: 55px;
  display: flex;
  border: 1px solid rgb(219, 219, 219);

  & .ant-input-number-input-wrap {
    width: 100%;

    & input {
      height: 100%;
      color: rgb(0, 0, 0);
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0;
      text-align: center;
    }
  }
`;

const InputBetweenTextStyled = styled.div`
  color: rgb(0, 0, 0);

  margin: 0 16px;
`;

export const MileagePick = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [mileage, setMileage] = useState<FromTo<number | null>>({ from: null, to: null });

  const mileageValue = useAppSelector((state) => state.filters.selectedData.mileage);
  useEffect(() => {
    if (mileageValue) setMileage(mileageValue);
  }, [mileageValue]);

  const handleInputChange = (type: FromToEnum) => (value: number | null) => {
    setMileage((prev) => ({ ...prev, [type]: value }));
  };

  const handleBlur = (type: FromToEnum) => () => {
    dispatch(FiltersActions.setMileage({ type, value: mileage[type] as number }));
  };
  return (
    <>
      <WrapperStyled id="mileage">
        <TitleStyled>{t("mileage")}</TitleStyled>
        <MileagePickWrapperStyled>
          <InputWrapper>
            <InputNumberStyled
              min={0}
              max={500000}
              value={mileage?.from}
              step={100}
              onChange={handleInputChange(FromToEnum.FROM)}
              placeholder={t("from")}
              onBlur={handleBlur(FromToEnum.FROM)}
            />
            <InputBetweenTextStyled>{t("to")}</InputBetweenTextStyled>
            <InputNumberStyled
              min={0}
              max={500000}
              value={mileage?.to}
              step={100}
              placeholder={t("to")}
              onChange={handleInputChange(FromToEnum.TO)}
              onBlur={handleBlur(FromToEnum.TO)}
            />
          </InputWrapper>
        </MileagePickWrapperStyled>
      </WrapperStyled>
    </>
  );
};

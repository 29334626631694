import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, createSearchParams } from "react-router-dom";
import { v4 } from "uuid";

import { useAppSelector } from "../../../helpers/store.hook";
import { SearchRoutesPath } from "../../../routes";
import { BTNTypes, ButtonStyled } from "../../common/Button";
import { SearchHistory } from "../../search/search.types";
import { createSeach } from "../../search/utils/createSearch";

export const SearchBtn = React.memo(() => {
  const { t } = useTranslation();
  const selectedData = useAppSelector((state) => state.filters.selectedData);

  const SearchUrl = useMemo(() => {
    const searchObject = createSeach(selectedData);
    const history = createSeach(selectedData, true);

    return {
      link: `?${createSearchParams(searchObject)}`,
      history: history,
    };
  }, [selectedData]);

  const handleHistory = useCallback(() => {
    const history = localStorage.getItem(SearchHistory.history);
    if (
      SearchUrl.link === "?page=1&limit=20" ||
      SearchUrl.link === "?page=1&limit=20&afterAccident=true"
    )
      return;
    if (history) {
      const parsedHistory = JSON.parse(history) as { date: Date; value: string }[];
      if (parsedHistory.length >= 5) {
        localStorage.setItem(
          SearchHistory.history,
          JSON.stringify([
            { date: new Date(), value: SearchUrl.history, link: SearchUrl.link, id: v4() },
            ...parsedHistory.slice(0, -1),
          ]),
        );
      } else {
        localStorage.setItem(
          SearchHistory.history,
          JSON.stringify([
            { date: new Date(), value: SearchUrl.history, link: SearchUrl.link, id: v4() },
            ...parsedHistory,
          ]),
        );
      }
    } else {
      localStorage.setItem(
        SearchHistory.history,
        JSON.stringify([
          { date: new Date(), value: SearchUrl.history, link: SearchUrl.link, id: v4() },
        ]),
      );
    }
  }, [SearchUrl]);

  return (
    <>
      <NavLink to={`${SearchRoutesPath.search}${SearchUrl.link}`}>
        <ButtonStyled $type={BTNTypes.PRIMARY} onClick={handleHistory}>
          {t("search")}
        </ButtonStyled>
      </NavLink>
    </>
  );
});

import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { BTNTypes, ButtonStyled } from "../../../../common/Button";
import { Modification, SelectedGeneration } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { createGenSearch } from "../../../utils/createSearch";
import { useSearch } from "../../../utils/useSearch";

const GenItemWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;

const GenItemStyled = styled.div<{ $isSelected: boolean }>`
  border: 1px solid ${(props) => (props.$isSelected ? "var(--main-color)" : "rgb(219, 219, 219)")};
  background: ${(props) => (props.$isSelected ? "var(--main-color)" : "transparent")};
  color: ${(props) => (props.$isSelected ? "rgb(255,255,255)" : "rgb(108, 108, 108)")};
  border-radius: 8px;
  cursor: pointer;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  padding: 16px;
`;

const GenWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const GenContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgb(219, 219, 219);
`;

const GenLabelStyled = styled.div`
  user-select: none;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  cursor: pointer;
  color: #2e2e2e;
  & span {
    user-select: none;
    color: var(--main-color);
  }
`;

const SelectedTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;

  & > button {
    padding: 8px;
  }
`;

const SelectedModificationTextStyled = styled.div`
  margin-top: 8px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  color: #bfbfbf;
  width: 100%;
  & div {
    color: #2e2e2e;
    overflow-wrap: break-word;
  }
`;

export const Generations = React.memo(() => {
  const dispatch = useAppDispatch();

  const [, setSearchParams] = useSearchParams();
  const { removeSearch } = useSearch();

  const generations = useAppSelector((state) => state.filters.generations);
  const selectedGenerations = useAppSelector((state) => state.filters.selectedData?.generations);
  const genList = useMemo(() => {
    return generations.map((item: SelectedGeneration) => {
      const isSelectedGen = selectedGenerations?.find((gen) => gen._id === item._id);
      if (isSelectedGen) return isSelectedGen;
      return item;
    }, []);
  }, [selectedGenerations, generations]);

  const handleModification = (
    gen: SelectedGeneration,
    mod: Modification & {
      isSelected?: boolean | undefined;
    },
  ) => {
    const isSelectedGen = selectedGenerations?.find((item) => item._id === gen._id);

    if (isSelectedGen) {
      const nextGen = selectedGenerations?.map((item) => {
        if (item._id === gen._id) {
          return {
            ...gen,
            options: gen.options.map((modItem) => {
              if (modItem._id === mod._id) {
                return {
                  ...modItem,
                  isSelected: !modItem.isSelected,
                };
              }
              return modItem;
            }),
          };
        }
        return item;
      });

      dispatch(FiltersActions.setGenerationSearch({ nextGen: nextGen || [] }));

      const { generations } = createGenSearch(nextGen || []);
      if (generations === "") {
        removeSearch("generation");
        return;
      }
      setSearchParams((prev) => {
        prev.set("generation", generations);
        return prev;
      });
    } else {
      const nextMod = gen.options.map((item) => {
        if (item._id === mod._id) {
          return {
            ...mod,
            isSelected: !mod.isSelected || true,
          };
        }
        return item;
      });
      dispatch(
        FiltersActions.setGenerationSearch({
          nextGen: [...(selectedGenerations || []), { ...gen, options: nextMod }],
        }),
      );
      const { generations } = createGenSearch([
        ...(selectedGenerations || []),
        { ...gen, options: nextMod },
      ]);

      if (generations === "") {
        removeSearch("generation");
        return;
      }

      setSearchParams((prev) => {
        prev.set("generation", generations);
        return prev;
      });
    }
  };

  const handleAllModification = (gen: SelectedGeneration) => () => {
    const isSelectedGen = selectedGenerations?.find((item) => item._id === gen._id);
    let nextGen: SelectedGeneration[] = [];
    if (isSelectedGen && selectedGenerations) {
      const isAllSelected = gen.options.every((item) => item.isSelected);

      nextGen = selectedGenerations.map((item) => {
        if (item._id === gen._id) {
          return {
            ...gen,
            options: gen.options.map((modItem) => {
              return {
                ...modItem,
                isSelected: !isAllSelected,
              };
            }),
          };
        }
        return item;
      });
    } else {
      nextGen = [
        ...(selectedGenerations || []),
        { ...gen, options: gen.options.map((item) => ({ ...item, isSelected: true })) },
      ];
    }
    dispatch(FiltersActions.setGenerationSearch({ nextGen: nextGen || [] }));
    if (nextGen.length === 0) {
      removeSearch("generation");
      return;
    }
    const { generations } = createGenSearch(nextGen || []);
    if (generations === "") {
      removeSearch("generation");
      return;
    }
    setSearchParams((prev) => {
      prev.set("generation", generations);
      return prev;
    });
  };

  return (
    <GenWrapperStyled>
      {genList.map((gen) => {
        return (
          <GenItem
            key={gen._id}
            gen={gen}
            onAllModification={handleAllModification}
            onModification={handleModification}
          />
        );
      })}
    </GenWrapperStyled>
  );
});

const GenItem: React.FC<{
  gen: SelectedGeneration;
  onAllModification: (gen: SelectedGeneration) => () => void;
  onModification: (
    gen: SelectedGeneration,
    mod: Modification & {
      isSelected?: boolean | undefined;
    },
  ) => void;
}> = React.memo(({ gen, onAllModification, onModification }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <GenContainerStyled key={gen._id}>
      <SelectedTitle>
        <GenLabelStyled onClick={onAllModification(gen)}>
          {gen.name}:{" "}
          <span>
            {gen.yearStart}-{gen.yearEnd || t("now")}
          </span>
        </GenLabelStyled>
        <ButtonStyled $type={BTNTypes.SECONDARY} onClick={handleOpen}>
          {isOpen ? t("close") : t("open")}
        </ButtonStyled>
      </SelectedTitle>
      {isOpen ? (
        <GenItemWrapperStyled>
          <ModificationItem gen={gen} key={gen._id} onModification={onModification} />
        </GenItemWrapperStyled>
      ) : (
        <ModItemText gen={gen} />
      )}
    </GenContainerStyled>
  );
});

const ModItemText: React.FC<{ gen: SelectedGeneration }> = React.memo(({ gen }) => {
  const { t } = useTranslation();
  const selectedMod = gen.options.filter((mod) => mod.isSelected).map((el) => el.name);
  return (
    <SelectedModificationTextStyled>
      {selectedMod.length ? (
        <>
          {t("selected-modification")} <div>{selectedMod.join(", ")}</div>
        </>
      ) : (
        t("no-selected-modification")
      )}
    </SelectedModificationTextStyled>
  );
});

export const ModificationItem: React.FC<{
  gen: SelectedGeneration;
  onModification: (
    gen: SelectedGeneration,
    mod: Modification & {
      isSelected?: boolean | undefined;
    },
  ) => void;
}> = React.memo(({ gen, onModification }) => {
  const handleGeneration =
    (
      gen: SelectedGeneration,
      mod: Modification & {
        isSelected?: boolean | undefined;
      },
    ) =>
    () => {
      onModification(gen, mod);
    };
  return (
    <>
      {gen.options.map((mod) => (
        <GenItemStyled
          key={mod._id}
          $isSelected={!!mod.isSelected}
          onClick={handleGeneration(gen, mod)}
        >
          {mod.name}
        </GenItemStyled>
      ))}
    </>
  );
});

import { Button } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { HistoryItem, HistoryItemValue } from "./item";
import { SearchHistory } from "../../../search/search.types";

const HistoryWrapperStyled = styled.div`
  border-radius: 8px 8px 0px 0px;
  background: rgb(240, 240, 240);
  padding: 48px;
  margin-top: 100px;
`;

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const TitleStyled = styled.div`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0;
`;

const ClearButtonStyled = styled(Button)`
  color: rgb(191, 191, 191);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
`;

const HistoryItemsWrapperStyled = styled.div`
  display: flex;
  gap: 12px;
`;

export const MainHistory: React.FC = () => {
  const { t } = useTranslation();
  const [history, setHistory] = useState<HistoryItemValue[]>([]);

  useEffect(() => {
    const history = localStorage.getItem(SearchHistory.history);
    if (history) {
      setHistory(JSON.parse(history));
    }
  }, []);

  if (!history.length) {
    return null;
  }

  const handleDelete = (id: string) => {
    const history = localStorage.getItem(SearchHistory.history);
    if (history) {
      const nextHistory = JSON.parse(history).filter(
        (historyItem: HistoryItemValue) => historyItem.id !== id,
      );
      localStorage.setItem(SearchHistory.history, JSON.stringify(nextHistory));
      setHistory(nextHistory);
    }
  };

  const handleClearAll = () => {
    localStorage.removeItem(SearchHistory.history);
    setHistory([]);
  };

  return (
    <HistoryWrapperStyled>
      <HeaderStyled>
        <TitleStyled>{t("main.history")}</TitleStyled>

        <ClearButtonStyled type="text" onClick={handleClearAll}>
          {t("main.clear")}
        </ClearButtonStyled>
      </HeaderStyled>
      <HistoryItemsWrapperStyled>
        {history.map((item, index) => (
          <HistoryItem key={item.link + index} item={item} onDelete={handleDelete} />
        ))}
      </HistoryItemsWrapperStyled>
    </HistoryWrapperStyled>
  );
};

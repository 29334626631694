import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { YearPickerComp } from "../../../../filters/components/years";
import { FromToEnum } from "../../../../filters/filters.types";

const YearPickWrapperStyled = styled.div`
  width: 100%;
  margin: 0 auto;
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
`;

const WrapperStyled = styled.div`
  border-radius: 8px;
  padding: 16px;
  background: rgb(255, 255, 255);
`;

const TitleStyled = styled.div`
  color: rgb(46, 46, 46);
  font-size: 18px;
  font-weight: 600;
  line-height: 175%;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InputBetweenTextStyled = styled.div`
  color: rgb(0, 0, 0);
  margin: 0 15px;
`;

const YearItemSelectWrapper = styled.div`
  flex: 1;
  height: 55px;
  & > div {
    width: 100%;
  }
`;

export const maxYear = 2025;

export const YearPick = () => {
  const { t } = useTranslation();

  return (
    <>
      <WrapperStyled id="year">
        <TitleStyled>{t("year")}</TitleStyled>
        <YearPickWrapperStyled>
          <InputWrapper>
            <YearItemSelectWrapper>
              <YearPickerComp type={FromToEnum.FROM} />
            </YearItemSelectWrapper>

            <InputBetweenTextStyled>{t("to")}</InputBetweenTextStyled>
            <YearItemSelectWrapper>
              <YearPickerComp type={FromToEnum.TO} />
            </YearItemSelectWrapper>
          </InputWrapper>
        </YearPickWrapperStyled>
      </WrapperStyled>
    </>
  );
};

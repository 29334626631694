import { Breadcrumb } from "antd";
import { useMemo } from "react";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { FiltersActions } from "../../../filters/redux/filters.slice";

const ItemStyled = styled.div`
  color: rgb(116, 99, 232);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
`;

const WrapperStyled = styled.div`
  margin-bottom: 16px;
`;

const BreadcrumbStyled = styled(Breadcrumb)`
  & li:last-child {
    & span > div {
      color: rgb(108, 108, 108);
    }
  }
`;

export const BreadCrumb = () => {
  const dispatch = useAppDispatch();
  const selectedModel = useAppSelector((state) => state.filters.selectedData.model);
  const selectedBrand = useAppSelector((state) => state.filters.selectedData.brand);
  const selectedGenerations = useAppSelector((state) => state.filters.selectedData.generations);

  const items = useMemo(() => {
    const res: {
      title: JSX.Element;
      onClick?: () => void;
      className?: string;
    }[] = [
      {
        title: <ItemStyled>Home</ItemStyled>,
        onClick: () => dispatch(FiltersActions.clearBrand()),
        className: "pointer",
      },
    ];
    if (selectedBrand) {
      res.push({
        title: <ItemStyled>{selectedBrand?.name}</ItemStyled>,
        onClick: () => dispatch(FiltersActions.clearModel()),
        className: "pointer",
      });
    }
    if (selectedModel) {
      res.push({
        title: <ItemStyled>{selectedModel?.name}</ItemStyled>,
        onClick: () => dispatch(FiltersActions.clearGenerations()),
        className: "pointer",
      });
    }
    if ((selectedGenerations?.length ?? 0) > 0) {
      res.push({
        title: <ItemStyled>{selectedGenerations?.map((gen) => gen.label).join(", ")}</ItemStyled>,
      });
    }
    res[res.length - 1].onClick = () => {
      return;
    };
    res[res.length - 1].className = "normal";
    return res;
  }, [selectedModel, selectedBrand, selectedGenerations, dispatch]);

  return (
    <WrapperStyled>
      <BreadcrumbStyled
        separator={
          <ItemStyled>
            <svg
              width="15.173828"
              height="10.455078"
              viewBox="0 0 15.1738 10.4551"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="east"
                d="M9.94 10.45L9.18 9.68L13.1 5.76L0 5.76L0 4.68L13.1 4.68L9.18 0.76L9.94 0L15.17 5.22L9.94 10.45Z"
                fill="#6C6C6C"
                fillOpacity="1.000000"
                fillRule="nonzero"
              />
            </svg>
          </ItemStyled>
        }
        items={items}
      />
    </WrapperStyled>
  );
};

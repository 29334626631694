import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { PreviewCarItem, SectionTitleStyled } from "../../../../pages/car/preview/PreviewCar";
import { icons } from "../../../common/icons";
import { AdditionalIonfoKeys } from "../../constant";

const PreviewInfoContainerStyled = styled.div`
  border: 1px solid rgb(219, 219, 219);
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 20px;
`;

const AdditionalInfoItemTitle = styled.h4`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgb(219, 219, 219);
`;

const AdditionalInfoItemContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

const AdditionalInfoItemStyled = styled.div`
  display: flex;
  align-items: center;
`;

const ImageStyled = styled.img`
  width: 150px;
  height: auto;
`;

const additionalInfoItems: AdditionalIonfoKeys[] = [
  AdditionalIonfoKeys.headlight,
  AdditionalIonfoKeys.optic,
  AdditionalIonfoKeys.salonMaterial,
  AdditionalIonfoKeys.salon,
  AdditionalIonfoKeys.security,
  AdditionalIonfoKeys.airbags,
  AdditionalIonfoKeys.multimedia,
  AdditionalIonfoKeys.other,
];

export const AdditionalInfo: React.FC<{ car: PreviewCarItem }> = ({ car }) => {
  const { t } = useTranslation();
  return (
    <>
      <SectionTitleStyled>{t("preview.additional")}</SectionTitleStyled>
      <>
        {additionalInfoItems.map((item) => (
          <div key={item}>
            {car.additionalOptions[item] && car.additionalOptions[item] && (
              <PreviewInfoContainerStyled>
                <AdditionalInfoItemTitle>{t(`addCar.${item}.label`)}</AdditionalInfoItemTitle>

                <AdditionalInfoItemContainerStyled>
                  {car.additionalOptions[item].length ? (
                    car.additionalOptions[item].map((option) => (
                      <AdditionalInfoItemStyled key={option._id}>
                        {t(option.searchName)}
                        <Tooltip
                          title={<ImageStyled src={icons[option.searchName]} alt={option.value} />}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask24_1753"
                              maskUnits="userSpaceOnUse"
                              x="0.000000"
                              y="0.000000"
                              width="24.000000"
                              height="24.000000"
                            >
                              <rect
                                id="Bounding box"
                                width="24.000000"
                                height="24.000000"
                                fill="#D9D9D9"
                                fillOpacity="1.000000"
                              />
                            </mask>
                            <g mask="url(#mask24_1753)">
                              <path
                                id="info"
                                d="M11.5 16.5L12.5 16.5L12.5 11L11.5 11L11.5 16.5ZM12 9.57C12.17 9.57 12.32 9.51 12.43 9.39C12.55 9.28 12.61 9.13 12.61 8.96C12.61 8.78 12.55 8.64 12.43 8.52C12.32 8.4 12.17 8.34 12 8.34C11.82 8.34 11.67 8.4 11.56 8.52C11.44 8.64 11.38 8.78 11.38 8.96C11.38 9.13 11.44 9.28 11.56 9.39C11.67 9.51 11.82 9.57 12 9.57ZM12 21C10.75 21 9.58 20.76 8.49 20.29C7.39 19.81 6.44 19.17 5.63 18.36C4.82 17.55 4.18 16.6 3.7 15.51C3.23 14.41 3 13.24 3 12C3 10.75 3.23 9.58 3.7 8.49C4.18 7.39 4.82 6.44 5.63 5.63C6.44 4.82 7.39 4.18 8.48 3.7C9.58 3.23 10.75 3 11.99 3C13.24 3 14.41 3.23 15.5 3.7C16.6 4.18 17.55 4.82 18.36 5.63C19.17 6.44 19.81 7.39 20.29 8.48C20.76 9.58 21 10.75 21 11.99C21 13.24 20.76 14.41 20.29 15.5C19.81 16.6 19.17 17.55 18.36 18.36C17.55 19.17 16.6 19.81 15.51 20.29C14.41 20.76 13.24 21 12 21Z"
                                fill="#DBDBDB"
                                fillOpacity="1.000000"
                                fillRule="nonzero"
                              />
                            </g>
                          </svg>
                        </Tooltip>
                      </AdditionalInfoItemStyled>
                    ))
                  ) : (
                    <>{t("no-info")}</>
                  )}
                </AdditionalInfoItemContainerStyled>
              </PreviewInfoContainerStyled>
            )}
          </div>
        ))}
      </>
    </>
  );
};

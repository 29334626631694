import { Carousel, ConfigProvider, Modal, Spin } from "antd";
import { CarouselRef } from "antd/es/carousel";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { PreviewCarItem } from "../../../../pages/car/preview/PreviewCar";

const SliderWrapperStyled = styled.div`
  display: flex;
  gap: 16px;
`;
const PrimarySlyderStyled = styled.div`
  width: 844px;
  height: 633px;
  & > div {
    height: 100%;

    & .slick-list {
      height: 100%;

      & .slick-track {
        height: 100%;
        & .slick-slide {
          height: 100%;
          & > div {
            height: 100%;
          }
        }
      }
    }
  }
`;
const SecondarySlyderStyled = styled.div`
  height: 633px;
  width: 257px;
  overflow: hidden;
  & > div {
    height: 100%;

    & .slick-list {
      height: 633px !important;
      margin: 0;
      padding: 0;

      & .slick-track {
        & .slick-slide {
          & > div {
            height: 100%;
          }
        }
      }
    }
  }
`;

const ImageWrapperStyled = styled.div`
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  position: relative;
  height: 100%;
`;

const ImageStyled = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  border-radius: 8px;
`;

const position = {
  left: css`
    left: 0;
    height: 80px;
    width: 80px;
    transform: translateY(-50%);
  `,
  right: css`
    right: 0;
    height: 80px;
    width: 80px;
    transform: translateY(-50%);
  `,
  top: css`
    top: 0;
    transform: rotate(90deg) translateY(-50%);
    right: 50%;
  `,
  bottom: css`
    bottom: 0;
    top: auto;
    transform: rotate(90deg) translateY(-50%);
    right: 50%;
  `,
};

const ArrorWrapperStyled = styled.div<{
  $position: "left" | "right" | "top" | "bottom";
  $disabled?: boolean;
}>`
  position: absolute;
  top: 50%;

  cursor: pointer;
  transition: transform 0.3s;
  width: 25px;
  height: 25px;
  ${(prop) => position[prop.$position]};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  svg {
    transition: transform 0.3s;
  }
  &:hover {
    & svg {
      transform: scale(1.2);
    }
  }
  ${(prop) => prop.$disabled && "display: none;"}
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SampleNextArrow(props: any) {
  const { onClick, currentSlide, slideCount } = props;
  return (
    <ArrorWrapperStyled
      $position="right"
      $disabled={currentSlide === slideCount - 1}
      onClick={onClick}
    >
      <svg
        width="9.863342"
        height="17.726654"
        viewBox="0 0 9.86334 17.7267"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="chevron_forward"
          d="M7.84 8.87L0 1.01L1.01 0L9.86 8.87L1.01 17.72L0 16.71L7.84 8.87Z"
          fill="#FFFFFF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </svg>
    </ArrorWrapperStyled>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SamplePrevArrow(props: any) {
  const { onClick, currentSlide } = props;
  return (
    <ArrorWrapperStyled $position="left" $disabled={currentSlide === 0} onClick={onClick}>
      <svg
        width="9.863342"
        height="17.726654"
        viewBox="0 0 9.86334 17.7267"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="chevron_backward"
          d="M8.85 17.72L0 8.87L8.85 0L9.86 1.01L2.02 8.87L9.86 16.71L8.85 17.72Z"
          fill="#FFFFFF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </svg>
    </ArrorWrapperStyled>
  );
}

const SecondarySlyderItemStyled = styled.div`
  width: 208px;
  height: 156px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
`;

const SecondarySlyderItemImageStyled = styled.img<{ $last?: boolean }>`
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
`;

const SecondarySlyderItemTextStyled = styled.div`
  color: rgb(248, 248, 248);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  cursor: pointer;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SampleNextSecondaryArrow({ onClick, currentSlide, slideCount }: any) {
  return (
    <ArrorWrapperStyled
      onClick={onClick}
      $position="bottom"
      $disabled={currentSlide + 4 === slideCount}
    >
      <svg
        width="9.863342"
        height="17.726654"
        viewBox="0 0 9.86334 17.7267"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="chevron_forward"
          d="M7.84 8.87L0 1.01L1.01 0L9.86 8.87L1.01 17.72L0 16.71L7.84 8.87Z"
          fill="#FFFFFF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </svg>
    </ArrorWrapperStyled>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SamplePrevSecondaryArrow({ onClick, currentSlide, slideCount }: any) {
  return (
    <ArrorWrapperStyled onClick={onClick} $position="top" $disabled={currentSlide === 0}>
      <svg
        width="9.863342"
        height="17.726654"
        viewBox="0 0 9.86334 17.7267"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="chevron_backward"
          d="M8.85 17.72L0 8.87L8.85 0L9.86 1.01L2.02 8.87L9.86 16.71L8.85 17.72Z"
          fill="#FFFFFF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </svg>
    </ArrorWrapperStyled>
  );
}

export const PreviewSlider: React.FC<{ car: PreviewCarItem }> = ({
  car: { photo, brand, model },
}) => {
  const { t } = useTranslation();
  const [photos] = useState<{ photo: string; id: number }[]>(
    photo.map((photo, index) => ({ photo, id: index })),
  );
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [nav1, setNav1] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [nav2, setNav2] = useState<any>(null);
  const sliderRef1 = useRef<CarouselRef>(null);
  const sliderRef2 = useRef<CarouselRef>(null);

  useEffect(() => {
    sliderRef1.current && setNav1(sliderRef1.current);
    sliderRef2.current && setNav2(sliderRef2.current);
  }, []);

  const handleOpenFullScreen = () => {
    setIsFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };

  const handleBeforeChange = (oldIndex: number, newIndex: number) => {
    setCurrentSlide(newIndex);
  };

  return (
    <>
      <SliderWrapperStyled>
        <PrimarySlyderStyled>
          <Carousel
            ref={sliderRef1}
            asNavFor={nav2}
            arrows={true}
            dots={false}
            swipe
            nextArrow={<SampleNextArrow />}
            prevArrow={<SamplePrevArrow />}
            infinite={false}
            beforeChange={handleBeforeChange}
            style={{ height: "100%" }}
          >
            {photos.map(({ photo, id }) => (
              <ImageWrapperStyled key={id}>
                <picture>
                  <source srcSet={photo} />
                  <ImageStyled
                    key={photo}
                    src={photo}
                    alt={`${brand} ${model}`}
                    onClick={handleOpenFullScreen}
                  />
                </picture>
              </ImageWrapperStyled>
            ))}
          </Carousel>
        </PrimarySlyderStyled>
        <SecondarySlyderStyled>
          <Carousel
            ref={sliderRef2}
            asNavFor={nav1}
            arrows={true}
            dots={false}
            slidesToShow={4}
            swipe
            vertical
            swipeToSlide
            focusOnSelect
            nextArrow={<SampleNextSecondaryArrow />}
            prevArrow={<SamplePrevSecondaryArrow />}
            infinite={false}
            style={{ height: "100%" }}
          >
            {photos.map(({ photo, id }, index) => (
              <SecondarySlyderItemStyled key={id}>
                <picture>
                  <source srcSet={photo} />
                  <SecondarySlyderItemImageStyled src={photo} alt={`${brand} ${model}`} />
                </picture>
                {index + 1 !== photos.length && photos.length > 4 && currentSlide + 3 === index && (
                  <SecondarySlyderItemTextStyled>
                    + {photos.length - currentSlide - 4} {t("photos")}
                  </SecondarySlyderItemTextStyled>
                )}
              </SecondarySlyderItemStyled>
            ))}
          </Carousel>
        </SecondarySlyderStyled>
      </SliderWrapperStyled>
      {isFullScreen && (
        <CarouselPreviewCar
          photos={photos}
          startedIndex={currentSlide}
          isOpen={isFullScreen}
          handleClose={handleCloseFullScreen}
        />
      )}
    </>
  );
};

const WIDTH = 192;

const modalStyles = {
  mask: {
    backdropFilter: "blur(10px)",
  },
  body: {
    height: "100vh",
    background: "#2E2E2E",
  },
};

const ModalWrapperStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MainCarouselWrapper = styled.div`
  height: calc(100% - 165px);
  padding: 16px;
  & > div {
    height: 100%;
  }
  & .slick-slide {
    & > div {
      height: 100%;
    }
  }

  & .slick-list {
    height: 100%;
  }
  & .slick-track {
    height: 100%;
  }
  & .carousel-container {
    height: 100%;
  }
`;

const CauruselImageWrapperStyled = styled.div`
  position: relative;
  height: 100%;
  display: flex !important;

  & img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
`;

const ThumbnailCarouselStyled = styled.div`
  height: 146px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 16px;
  & > div {
    height: 100%;

    & .slick-list {
      margin: 0;
      padding: 0;

      & .slick-track {
        & .slick-slide {
          border: 1px solid var(--main-color);
          border-radius: 8px;
          height: 144px;
          overflow: hidden;

          & > div {
            height: 100%;
          }
        }
      }
    }
  }
`;

const ThumbnailCarouselImageStyled = styled.div`
  width: ${WIDTH}px;
  height: 144px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
`;

const CarouselPreviewCar: React.FC<{
  isOpen: boolean;
  handleClose: () => void;
  startedIndex: number;
  photos: { photo: string; id: number }[];
}> = ({ isOpen, handleClose, startedIndex, photos }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [slideToShow, setSlideToShow] = useState<number | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [nav1, setNav1] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [nav2, setNav2] = useState<any>(null);
  const sliderRef1 = useRef<CarouselRef>(null);
  const sliderRef2 = useRef<CarouselRef>(null);

  useEffect(() => {
    if (slideToShow) {
      setNav1(sliderRef1.current);
      setNav2(sliderRef2.current);
    }
  }, [slideToShow]);

  useEffect(() => {
    const width = modalRef.current?.clientWidth;
    if (width) {
      const slides = Math.floor(width / WIDTH);
      setSlideToShow(slides);
    }
  }, [isOpen]);

  return (
    <ConfigProvider
      modal={{
        styles: modalStyles,
      }}
    >
      <Modal
        open={isOpen}
        onCancel={handleClose}
        footer={null}
        width={"100%"}
        style={{ height: "100vh" }}
        centered
        className="AddCarModal PreviewCarModal"
      >
        <ModalWrapperStyled ref={modalRef}>
          {slideToShow === null ? (
            <Spin />
          ) : (
            <>
              <MainCarouselWrapper>
                <Carousel
                  autoplay={false}
                  draggable
                  arrows
                  dots={false}
                  style={{ height: "100%" }}
                  initialSlide={startedIndex}
                  infinite={true}
                  asNavFor={nav2}
                  ref={sliderRef1}
                  nextArrow={<SampleNextArrow />}
                  prevArrow={<SamplePrevArrow />}
                >
                  {photos.map(({ photo, id: _id }, index) => (
                    <CauruselImageWrapperStyled key={index}>
                      <img src={photo} alt={`Slide ${index}`} />
                    </CauruselImageWrapperStyled>
                  ))}
                </Carousel>
              </MainCarouselWrapper>

              <ThumbnailCarouselStyled>
                <Carousel
                  ref={sliderRef2}
                  asNavFor={nav1}
                  arrows={true}
                  dots={false}
                  slidesToShow={slideToShow}
                  initialSlide={
                    photos.length - startedIndex - slideToShow <= 0
                      ? photos.length - slideToShow
                      : startedIndex
                  }
                  swipe
                  swipeToSlide
                  focusOnSelect
                  nextArrow={<SampleNextArrow />}
                  prevArrow={<SamplePrevArrow />}
                  infinite={false}
                  style={{ height: "100%" }}
                >
                  {photos.map(({ photo }, index) => (
                    <ThumbnailCarouselImageStyled
                      key={index}
                      onClick={() => sliderRef2.current?.goTo(index)}
                    >
                      <picture>
                        <source srcSet={photo} />
                        <SecondarySlyderItemImageStyled src={photo} alt={`Thumbnail ${index}`} />
                      </picture>
                    </ThumbnailCarouselImageStyled>
                  ))}
                </Carousel>
              </ThumbnailCarouselStyled>
            </>
          )}
        </ModalWrapperStyled>
      </Modal>
    </ConfigProvider>
  );
};

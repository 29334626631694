import { InputNumber, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAppSelector } from "../../../helpers/store.hook";
import { BTNTypes, ButtonStyled } from "../Button";

const ContainerStyled = styled.div`
  padding: 48px;
  border-radius: 12px;
  background: rgb(255, 255, 255);
`;
const HeaderStyled = styled.div`
  margin-bottom: 38px;
`;
const TitleStyled = styled.div`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  text-align: center;
`;
const SubtitleStyled = styled.div<{ $visible?: boolean }>`
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #bfbfbf;
  & span {
    color: #2e2e2e;
  }
`;
const CodeWrapperStyled = styled.div`
  width: 100%;
  height: 60px;
  margin-bottom: 48px;

  & * {
    width: 100%;
    height: 100%;
    font-size: 22px;
    text-align: center;
    font-family: Roboto;
    font-weight: 500;
  }
`;
const ActionsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TimeStyled = styled.span`
  margin-left: 5px;
  color: #7463e8;
`;

export const PhoneConfirmPopup: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const phone = useAppSelector((state) => state.auth.phone);
  const [time, setTimer] = useState(0);
  const [code, setCode] = useState<number | null>(null);
  const interval = useRef<NodeJS.Timer | null>(null);

  const handleSendAgain = () => {
    console.log("send again");
    setTimer(60);
    interval.current = setInterval(() => {
      setTimer((prev) => {
        if (prev === 0) {
          if (interval.current) clearInterval(interval.current);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, []);
  const handleCancel = () => {
    if (interval.current) clearInterval(interval.current);
    setCode(null);
    onClose();
  };

  const handleCode = (value: number | null) => {
    if (value && value > 999999) return setCode((prev) => prev);
    setCode(value);
  };

  return (
    <Modal open={isOpen} footer={null} onCancel={handleCancel} destroyOnClose>
      <ContainerStyled>
        <HeaderStyled>
          <TitleStyled>{t("phone.title")}</TitleStyled>
          <SubtitleStyled $visible={time > 0}>
            {t("phone.subtitle")}
            <span>+{phone}</span>
          </SubtitleStyled>

        </HeaderStyled>
        <CodeWrapperStyled>
          <InputNumber<number> value={code} controls={false} onChange={handleCode} />
        </CodeWrapperStyled>
        <ActionsWrapperStyled>
          <ButtonStyled $type={BTNTypes.SECONDARY} disabled={time > 0} onClick={handleSendAgain}>
            {t("phone.send-again")}
            {!!time && <TimeStyled>{time}</TimeStyled>}
          </ButtonStyled>
          <ButtonStyled $type={BTNTypes.PRIMARY} disabled={code === null}>
            {t("phone.confirm")}
          </ButtonStyled>
          <ButtonStyled $type={BTNTypes.SECONDARY} onClick={handleCancel}>
            {t("phone.cancel")}
          </ButtonStyled>
        </ActionsWrapperStyled>
      </ContainerStyled>
    </Modal>
  );
};

import { Button } from "antd";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import {
  AdditionalInfoSearchSelectedDataKeys,
  AIRBAG,
  BODY_TYPE,
  BRAND,
  COLOR,
  DRIVE_TYPE,
  FUEL,
  GEAR,
  HEADLIGHTS,
  MILEAGE,
  MODEL,
  MULTIMEDIA,
  OPTIC,
  OTHER,
  POWER,
  PRICE,
  REGION,
  SALON,
  SALON_MATERIAL,
  SECURITY,
  USE_INFO,
  USE_INFO_ACCIDENT,
  YEAR,
} from "../../../../constants/AdditionalInfo";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import i18next from "../../../../i18n/config";
import { SelectedData } from "../../../filters/filters.types";
import { FiltersActions } from "../../../filters/redux/filters.slice";

const SelectedItem = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid rgb(219, 219, 219);
  background: rgb(240, 240, 240);
  padding: 8px;
  border-radius: 32px;
  color: rgb(17, 17, 17);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0;
  & svg {
    cursor: pointer;
    margin: 8px;
  }
`;

const ActionWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextStyled = styled.div`
  margin-left: 8px;
`;

const WrapperStyled = styled.div`
  border-top: 1px solid rgb(219, 219, 219);
  padding: 10px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ContainerStyled = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const textParcer = (selectedData: SelectedData) => {
  const result: { type: keyof SelectedData; label: string; value?: string; parent?: string }[] = [];
  if (selectedData.afterAccident) {
    result.push({
      type: USE_INFO_ACCIDENT,
      label: i18next.t("afterAccident"),
      value: "afterAccident",
    });
  }
  if (selectedData.useInfo) {
    selectedData.useInfo.forEach((item) => {
      result.push({ type: USE_INFO, label: i18next.t(item), value: item });
    });
  }
  if (selectedData.brand) {
    result.push({ type: BRAND, label: selectedData.brand.name, value: selectedData.brand.name });
  }
  if (selectedData.model) {
    result.push({ type: MODEL, label: selectedData.model.name, value: selectedData.model.name });
  }
  if (selectedData.generations) {
    selectedData.generations.forEach((item) => {
      const selectedOptions = item.options.filter((option) => option.isSelected);
      if (selectedOptions.length) {
        selectedOptions.forEach((option) => {
          result.push({
            type: "generations",
            label: `${item.name}: ${option.name}`,
            value: option._id,
            parent: item._id,
          });
        });
      } else {
        result.push({ type: "generations", label: item.name, value: item._id });
      }
    });
  }
  if (selectedData.price) {
    if (selectedData.price.from && selectedData.price.to) {
      result.push({
        type: PRICE,
        label: `${i18next.t("price")}: ${selectedData.price.from} - ${selectedData.price.to}`,
      });
    } else if (selectedData.price.from) {
      result.push({
        type: PRICE,
        label: `${i18next.t("price")} ${i18next.t("from")} ${selectedData.price.from}`,
      });
    } else if (selectedData.price.to) {
      result.push({
        type: PRICE,
        label: `${i18next.t("price")} ${i18next.t("to")} ${selectedData.price.to}`,
      });
    }
  }
  if (selectedData.year) {
    if (selectedData.year.from && selectedData.year.to) {
      result.push({
        type: YEAR,
        label: `${i18next.t("year")} ${selectedData.year.from} - ${selectedData.year.to}`,
      });
    } else if (selectedData.year.from) {
      result.push({
        type: YEAR,
        label: `${i18next.t("year")} ${i18next.t("from")} ${selectedData.year.from}`,
      });
    } else if (selectedData.year.to) {
      result.push({
        type: YEAR,
        label: `${i18next.t("year")} ${i18next.t("to")} ${selectedData.year.to}`,
      });
    }
  }
  if (selectedData.mileage) {
    if (selectedData.mileage.from && selectedData.mileage.to) {
      result.push({
        type: MILEAGE,
        label: `${i18next.t("mileage")} ${selectedData.mileage.from} - ${selectedData.mileage.to}`,
      });
    } else if (selectedData.mileage.from) {
      result.push({
        type: MILEAGE,
        label: `${i18next.t("mileage")} ${i18next.t("from")} ${selectedData.mileage.from}`,
      });
    } else if (selectedData.mileage.to) {
      result.push({
        type: MILEAGE,
        label: `${i18next.t("mileage")} ${i18next.t("to")} ${selectedData.mileage.to}`,
      });
    }
  }
  if (selectedData.power) {
    if (selectedData.power.from && selectedData.power.to) {
      result.push({
        type: POWER,
        label: `${i18next.t("power")} ${selectedData.power.from} - ${selectedData.power.to}`,
      });
    } else if (selectedData.power.from) {
      result.push({
        type: POWER,
        label: `${i18next.t("power")} ${i18next.t("from")} ${selectedData.power.from}`,
      });
    } else if (selectedData.power.to) {
      result.push({
        type: POWER,
        label: `${i18next.t("power")} ${i18next.t("to")} ${selectedData.power.to}`,
      });
    }
  }
  if (selectedData.region) {
    result.push({
      type: REGION,
      label: `${i18next.t("region")} ${selectedData.region.join(", ")}`,
    });
  }
  if (selectedData.driveType) {
    selectedData.driveType.forEach((item) => {
      result.push({
        type: AdditionalInfoSearchSelectedDataKeys.DRIVE_TYPE,
        label: `${i18next.t("driveType")} ${i18next.t(item)}`,
        value: item,
      });
    });
  }
  if (selectedData.fuel) {
    selectedData.fuel.forEach((item) => {
      result.push({
        type: AdditionalInfoSearchSelectedDataKeys.FUEL,
        label: i18next.t(item),
        value: item,
      });
    });
  }
  if (selectedData.bodyType) {
    selectedData.bodyType.forEach((item) => {
      result.push({
        type: AdditionalInfoSearchSelectedDataKeys.BODY_TYPE,
        label: i18next.t(item),
        value: item,
      });
    });
  }
  if (selectedData.color) {
    selectedData.color.forEach((item) => {
      result.push({
        type: AdditionalInfoSearchSelectedDataKeys.COLOR,
        label: i18next.t(item),
        value: item,
      });
    });
  }
  if (selectedData.gear) {
    selectedData.gear.forEach((item) => {
      result.push({
        type: AdditionalInfoSearchSelectedDataKeys.GEAR,
        label: i18next.t(item),
        value: item,
      });
    });
  }
  if (selectedData.headlight) {
    selectedData.headlight.forEach((item) => {
      result.push({
        type: AdditionalInfoSearchSelectedDataKeys.HEADLIGHTS,
        label: i18next.t(item),
        value: item,
      });
    });
  }
  if (selectedData.optic) {
    selectedData.optic.forEach((item) => {
      result.push({
        type: AdditionalInfoSearchSelectedDataKeys.OPTIC,
        label: i18next.t(item),
        value: item,
      });
    });
  }
  if (selectedData.salonMaterial) {
    selectedData.salonMaterial.forEach((item) => {
      result.push({
        type: AdditionalInfoSearchSelectedDataKeys.SALON_MATERIAL,
        label: i18next.t(item),
        value: item,
      });
    });
  }
  if (selectedData.salon) {
    selectedData.salon.forEach((item) => {
      result.push({
        type: AdditionalInfoSearchSelectedDataKeys.SALON,
        label: i18next.t(item),
        value: item,
      });
    });
  }
  if (selectedData.security) {
    selectedData.security.forEach((item) => {
      result.push({
        type: AdditionalInfoSearchSelectedDataKeys.SECURITY,
        label: i18next.t(item),
        value: item,
      });
    });
  }
  if (selectedData.airbag) {
    selectedData.airbag.forEach((item) => {
      result.push({
        type: AdditionalInfoSearchSelectedDataKeys.AIRBAG,
        label: i18next.t(item),
        value: item,
      });
    });
  }
  if (selectedData.multimedia) {
    selectedData.multimedia.forEach((item) => {
      result.push({
        type: AdditionalInfoSearchSelectedDataKeys.MULTIMEDIA,
        label: i18next.t(item),
        value: item,
      });
    });
  }
  if (selectedData.other) {
    selectedData.other.forEach((item) => {
      result.push({
        type: AdditionalInfoSearchSelectedDataKeys.OTHER,
        label: i18next.t(item),
        value: item,
      });
    });
  }
  return result;
};

export const SelectedItems = React.memo(() => {
  const dispatch = useAppDispatch();
  const selectedData = useAppSelector((state) => state.filters.selectedData);
  const [isOpen, setIsOpen] = useState(false);

  const options = useMemo(() => {
    const value = textParcer(selectedData);

    return value;
  }, [selectedData]);

  const handleShowAll = () => {
    setIsOpen((prev) => !prev);
  };

  const handleRemove =
    (type: keyof SelectedData, label: string, value?: string, parent?: string) => () => {
      if (type === USE_INFO && value) {
        return dispatch(FiltersActions.setUseInfo(value));
      }
      if (
        type === REGION ||
        type === USE_INFO_ACCIDENT ||
        type === PRICE ||
        type === YEAR ||
        type === MILEAGE ||
        type === POWER
      ) {
        return dispatch(FiltersActions.clearSimpleType(type));
      }
      if (type === BRAND) {
        return dispatch(FiltersActions.clearBrand());
      }
      if (type === MODEL) {
        return dispatch(FiltersActions.clearModel());
      }
      if (type === "generations") {
        if (value && parent) {
          return dispatch(
            FiltersActions.setModification({ modification: { _id: value }, parentId: parent }),
          );
        } else if (value) {
          return dispatch(FiltersActions.clearGeneration(value));
        }
      }
      if (type === GEAR && value) {
        return dispatch(FiltersActions.setGear(value));
      }
      if (type === FUEL && value) {
        return dispatch(FiltersActions.setFuel(value));
      }
      if (type === BODY_TYPE && value) {
        return dispatch(FiltersActions.setBodyType(value));
      }
      if (type === COLOR && value) {
        return dispatch(FiltersActions.setColor(value));
      }
      if (type === DRIVE_TYPE && value) {
        return dispatch(FiltersActions.setDriveType(value));
      }
      if (type === HEADLIGHTS && value) {
        return dispatch(FiltersActions.setHeadlight(value));
      }
      if (type === OPTIC && value) {
        return dispatch(FiltersActions.setOptic(value));
      }
      if (type === SALON_MATERIAL && value) {
        return dispatch(FiltersActions.setSalonMaterial(value));
      }
      if (type === SALON && value) {
        return dispatch(FiltersActions.setSalon(value));
      }
      if (type === SECURITY && value) {
        return dispatch(FiltersActions.setSecurity(value));
      }
      if (type === AIRBAG && value) {
        return dispatch(FiltersActions.setAirBag(value));
      }
      if (type === OTHER && value) {
        return dispatch(FiltersActions.setOther(value));
      }
      if (type === MULTIMEDIA && value) {
        return dispatch(FiltersActions.setMultimedia(value));
      }
    };

  return (
    <WrapperStyled>
      <ContainerStyled>
        {options.slice(0, isOpen ? options.length : 5).map((item) => (
          <SelectedItem key={item.type + item.label + item.value + item.parent}>
            <TextStyled>{item.label}</TextStyled>
            <ActionWrapperStyled>
              <svg
                onClick={handleRemove(item.type, item.label, item.value, item.parent)}
                width="9.321152"
                height="9.321045"
                viewBox="0 0 9.32115 9.32104"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id="close_small"
                  d="M0.72 9.32L0 8.59L3.94 4.64L0 0.72L0.72 0L4.67 3.94L8.59 0L9.32 0.72L5.37 4.64L9.32 8.59L8.59 9.32L4.67 5.37L0.72 9.32Z"
                  fill="#2E2E2E"
                  fillOpacity="1.000000"
                  fillRule="nonzero"
                />
              </svg>
            </ActionWrapperStyled>
          </SelectedItem>
        ))}
      </ContainerStyled>
      {options.length > 5 && (
        <Button onClick={handleShowAll}>
          {isOpen ? i18next.t("history.hide") : i18next.t("history.show-more")}
        </Button>
      )}
    </WrapperStyled>
  );
});

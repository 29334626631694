import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { FromTo, FromToEnum } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { useSearch } from "../../../utils/useSearch";
import { FromToWrapperStyled, InputNumberStyled } from "../../searchFilter";

export const PriceInline = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { manageSearch, removeSearch } = useSearch();
  const selectedPrice = useAppSelector((state) => state.filters.selectedData.price);
  const [price, setPrice] = useState<FromTo<number | null>>({ from: null, to: null });
  const isSet = useRef(false);
  const handleChangePrice = (type: FromToEnum) => (value: string | null) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value as string) || value === "" || value === null) {
      setPrice((prev) => ({ ...prev, [type]: Number(value) || undefined }));
    }
  };

  useEffect(() => {
    if (selectedPrice && !isSet.current) {
      setPrice(selectedPrice);
      isSet.current = true;
    }
  }, [selectedPrice]);

  const handleBlur = (type: FromToEnum) => () => {
    if (price && price[type]) {
      dispatch(FiltersActions.setPrice({ type, value: price[type] as number }));
      let to, from;
      if (price.from && price.to && price.from > price.to) {
        to = price.from;
        from = price.to;
        setPrice({ from, to });
        manageSearch("priceFrom", String(from));
        manageSearch("priceTo", String(to));
        return;
      }
      manageSearch(type === FromToEnum.FROM ? "priceFrom" : "priceTo", String(price[type]));
    } else {
      removeSearch(type === FromToEnum.FROM ? "priceFrom" : "priceTo");
    }
  };

  return (
    <FromToWrapperStyled>
      <InputNumberStyled
        value={price?.from}
        onChange={handleChangePrice(FromToEnum.FROM)}
        onBlur={handleBlur(FromToEnum.FROM)}
        step={100}
        min={0}
        placeholder={t("from")}
      />
      <InputNumberStyled
        value={price?.to}
        onChange={handleChangePrice(FromToEnum.TO)}
        onBlur={handleBlur(FromToEnum.TO)}
        step={100}
        min={0}
        placeholder={t("to")}
      />
    </FromToWrapperStyled>
  );
};

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { FormAddCar } from "../../../components/car/components/addCar/form";
import { MenuAddCar } from "../../../components/car/components/addCar/menu";
import {
  CarGetAdditionalInfoThunk,
  CarGetBrandsThunk,
  getCarByIdThunk,
} from "../../../components/car/redux/car.thunks";
import { useAppDispatch } from "../../../helpers/store.hook";

const WrapperStyled = styled.div`
  max-width: 1600px;
  margin: 20px auto;
  display: flex;
  gap: 10px;
`;

interface AddCarPageProps {
  edit?: boolean;
  isAdmin?: boolean;
}

export const CarAdd: React.FC<AddCarPageProps> = ({ edit, isAdmin }) => {
  const dispatch = useAppDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(CarGetBrandsThunk());
    dispatch(CarGetAdditionalInfoThunk());
  }, [dispatch]);

  useEffect(() => {
    if (params.id && edit) dispatch(getCarByIdThunk({ _id: params.id }));
  }, [dispatch, edit, params.id]);

  return (
    <WrapperStyled>
      <MenuAddCar />
      <FormAddCar edit={edit} />
    </WrapperStyled>
  );
};

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { FromToEnum } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { useSearch } from "../../../utils/useSearch";
import { FromToWrapperStyled, InputNumberStyled } from "../../searchFilter";

export const PowerInline = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isSet = useRef(false);
  const { manageSearch, removeSearch } = useSearch();
  const selectedPower = useAppSelector((state) => state.filters.selectedData.power);
  const [power, setPower] = useState(selectedPower);
  const handleChangePower = (type: FromToEnum) => (value: string | null) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value as string) || value === "" || value === null) {
      setPower((prev) => ({ ...prev, [type]: Number(value) || undefined }));
    }
  };

  useEffect(() => {
    if (selectedPower && !isSet.current) {
      setPower(selectedPower);
      isSet.current = true;
    }
  }, [selectedPower]);

  const handleBlur = (type: FromToEnum) => () => {
    if (power && power[type]) {
      dispatch(FiltersActions.setPowers({ type, value: power[type] as number }));
      let to, from;
      if (power.from && power.to && power.from > power.to) {
        to = power.from;
        from = power.to;
        setPower({ from, to });
        manageSearch("powerFrom", String(from));
        manageSearch("powerTo", String(to));
        return;
      }
      manageSearch(type === FromToEnum.FROM ? "powerFrom" : "powerTo", String(power[type]));
    } else {
      removeSearch(type === FromToEnum.FROM ? "powerFrom" : "powerTo");
    }
  };
  return (
    <FromToWrapperStyled>
      <InputNumberStyled
        value={power?.from}
        onChange={handleChangePower(FromToEnum.FROM)}
        onBlur={handleBlur(FromToEnum.FROM)}
        min={0}
        placeholder={t("from")}
      />
      <InputNumberStyled
        value={power?.to}
        onChange={handleChangePower(FromToEnum.TO)}
        onBlur={handleBlur(FromToEnum.TO)}
        min={0}
        placeholder={t("to")}
      />
    </FromToWrapperStyled>
  );
};

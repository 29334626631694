import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import logo from "./muna_favicon_48x48.png";
import { ContainerAppStyled } from "../../App";
import { CarRoutesPath, NotFoundRoutesPath, SearchRoutesPath, UserRoutesPath } from "../../routes";

const ContainerStyled = styled.div`
  margin-top: 15px;
  padding-top: 48px;
  padding-bottom: 48px;
  background: rgb(46, 46, 46);
`;

const WrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  color: rgb(255, 255, 255);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0%;
  text-align: left;
`;

const CoppyrightStyled = styled.div`
  color: rgb(255, 255, 255);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  border-top: 1px solid rgb(255, 255, 255);
  padding-top: 15px;
  margin-top: 15px;
  width: 100%;
`;

const InfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
`;

const LogoStyled = styled.div`
  & img {
    border-radius: 50%;
    overflow: hidden;
  }
`;

const DescriptionStyled = styled.div``;

const PagesWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const Titletyled = styled.div`
  color: rgb(191, 191, 191);
  font-size: 18px;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 15px;
`;

const ContactStyled = styled.div``;

const ContactLinksStyled = styled.div``;

const SocialWrapperStyled = styled.div``;

const SocialItemStyled = styled.div``;

const NavLinkStyled = styled(NavLink)`
  text-decoration: none;
  color: rgb(255, 255, 255);
  &:hover {
    color: var(--main-color);
  }
`;

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <ContainerStyled>
      <ContainerAppStyled>
        <WrapperStyled>
          <InfoStyled>
            <LogoStyled>
              <img src={logo} alt="logo" />
            </LogoStyled>
            <DescriptionStyled>{t("footer.description")}</DescriptionStyled>
          </InfoStyled>
          <PagesWrapperStyled>
            <Titletyled>{t("footer.pages")}</Titletyled>
            <NavLinkStyled to="/">{t("footer.home")}</NavLinkStyled>
            <NavLinkStyled to={SearchRoutesPath.search}>{t("footer.search")}</NavLinkStyled>
            <NavLinkStyled to={SearchRoutesPath.searchAdvanced}>
              {t("footer.search-advanced")}
            </NavLinkStyled>
            <NavLinkStyled to={`${CarRoutesPath.car}${CarRoutesPath.add}`}>
              {t("footer.add-car")}
            </NavLinkStyled>
            <NavLinkStyled to={`${UserRoutesPath.user}${UserRoutesPath.cars}`}>
              {t("footer.user-cars")}
            </NavLinkStyled>
            <NavLinkStyled to={`${CarRoutesPath.car}${CarRoutesPath.favourite}`}>
              {t("footer.favourite")}
            </NavLinkStyled>
          </PagesWrapperStyled>
          <PagesWrapperStyled>
            <Titletyled>{t("footer.pages-utility")}</Titletyled>
            <NavLinkStyled to={NotFoundRoutesPath.notFound}>{t("footer.404")}</NavLinkStyled>
            <NavLinkStyled to="/">Police</NavLinkStyled>
            <NavLinkStyled to="/">Cookies</NavLinkStyled>
          </PagesWrapperStyled>
          <ContactStyled>
            <Titletyled>Contacts</Titletyled>
            <ContactLinksStyled>lorem@example.com</ContactLinksStyled>
            <SocialWrapperStyled>
              <SocialItemStyled></SocialItemStyled>
              <SocialItemStyled></SocialItemStyled>
              <SocialItemStyled></SocialItemStyled>
            </SocialWrapperStyled>
          </ContactStyled>
        </WrapperStyled>
        <WrapperStyled>
          <CoppyrightStyled>Copyright © 2024. All rights reserved.</CoppyrightStyled>
        </WrapperStyled>
      </ContainerAppStyled>
    </ContainerStyled>
  );
};
